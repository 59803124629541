const business = {
  namespaced: true,
  state: {
    businessInformation: {}, //商务基本信息
    userAssignmentMap: {}, //商务成员
    businessCostFileList: [], //商务报销附件
    projectInformation: {}, //项目基本信息
    projectCostFileList: [], //项目报销附件
    CompanyMembers: [], //公司成员-用于添加部门前缀或根据ID查询名称
    selectDeptList: [], //选择用户弹窗-部门
    allUserList: [], //不分页获取所有用户
    businessProjectList: [], //商务关联项目
    bidManagementResp: {}, //投标信息
  },
  getters: {},
  mutations: {
    SET_BID_INFO: (state, payload) => {
      state.bidManagementResp = payload.bidManagementResp
    },
    SET_BUSINESS_PROJECT_LIST: (state, payload) => {
      state.businessProjectList = payload.businessProjectList
    },
    SET_ALL_USER_LIST: (state, payload) => {
      state.allUserList = payload.allUserList
    },
    SET_DEPT_LIST: (state, payload) => {
      state.selectDeptList = payload.selectDeptList
    },
    SET_BUSINESS_INFORMATION: (state, payload) => {
      state.businessInformation = payload.businessInformation
    },
    SET_USER_ASSIGNMENT_MAP: (state, payload) => {
      state.userAssignmentMap = payload.userAssignmentMap
    },
    SET_COMPANY_MEBERS: (state, payload) => {
      state.CompanyMembers = payload.CompanyMembers
    },
    BUSINESSCOSTFILELIST: (state, payload) => {
      state.businessCostFileList = payload.businessCostFileList
    },
    PROJECTCOSTFILELIST: (state, payload) => {
      state.projectCostFileList = payload.projectCostFileList
    },
  },
  actions: {},
}

export default business
