import Axios from "@/axios";
import { Message } from "element-ui";
import store from "@/store";

// 规则列表
export const generalRuleList = function(cond = {}) {
  return Axios.post("/generalRule/list", cond);
};

// 编辑规则
export const generalRuleEdit = function(cond = {}) {
  return Axios.post("/generalRule/edit", cond);
};

// 规则变更列表
export const generalRuleLogList = function(cond = {}) {
  return Axios.post("/generalRule/logList", cond);
};

// 规则变更列表
export const selectLogDetailById = function(id) {
  return Axios.post("/generalRule/selectLogDetailById/" + id);
};
