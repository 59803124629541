import Axios from '@/axios'

// 创建并提交信息
export const submit = function (cond = {}) {
  return Axios.post('/paymentApplication/submit', cond)
}

// // 添加暂存信息
// export const undetermined = function(cond = {}) {
//   return Axios.post("/paymentApplication/undetermined", cond)
// }

// // 暂存状态编辑信息
// export const undeterminedEdit = function(cond = {}) {
//   return Axios.post("/paymentApplication/undeterminedEdit", cond)
// }

// 暂存状态编辑并提交信息
export const undeterminedEditAndSubmit = function (cond = {}) {
  return Axios.post('/paymentApplication/undeterminedEditAndSubmit', cond)
}

// 发放列表
export const getAuditPassList = function (cond = {}) {
  return Axios.post('/paymentApplication/getAuditPassList', cond)
}

// // -申请列表
// export const getApplyForList = function(cond = {}) {
//   return Axios.post("/paymentApplication/getApplyForList", cond)
// }

// 审核列表
export const getAuditList = function (cond = {}) {
  return Axios.post('/paymentApplication/getAuditList', cond)
}

// 全部列表
export const getList = function (cond = {}) {
  return Axios.post('/paymentApplication/getList', cond)
}

// // 撤回
// export const reject = function(cond = {}) {
//   return Axios.post("/paymentApplication/reject", cond)
// }

// 删除付款申请单
export const remove = function (cond) {
  return Axios.post('/paymentApplication/remove/' + cond)
}

// 查看详细信息
export const getDetailed = function (cond) {
  return Axios.post('/paymentApplication/getDetailed/' + cond)
}

// // 申请列表统计值
// export const getApplyStatistics = function(cond = {}) {
//   return Axios.post("/paymentApplication/getApplyStatistics", cond)
// }

// 项目经理审批不通过
export const auditManagerReject = function (cond = {}) {
  return Axios.post('/paymentApplication/auditManagerReject', cond)
}

// 项目经理审批通过
export const auditManagerPass = function (cond = {}) {
  return Axios.post('/paymentApplication/auditManagerPass', cond)
}

// 分管高管审批不通过
export const auditFzReject = function (cond = {}) {
  return Axios.post('/paymentApplication/auditFzReject', cond)
}

// 分管高管审批通过
export const auditFzPass = function (cond = {}) {
  return Axios.post('/paymentApplication/auditFzPass', cond)
}

// 董事长审批不通过
export const auditDszeject = function (cond = {}) {
  return Axios.post('/paymentApplication/auditDszeject', cond)
}

// 董事长审批通过
export const auditDszPass = function (cond = {}) {
  return Axios.post('/paymentApplication/auditDszPass', cond)
}

// 财务高管审批不通过
export const auditCwFzReject = function (cond = {}) {
  return Axios.post('/paymentApplication/auditCwFzReject', cond)
}

// 财务高管审批通过
export const auditCwFzPass = function (cond = {}) {
  return Axios.post('/paymentApplication/auditCwFzPass', cond)
}

// // 财务确认付款
// export const affirmApply = function(cond = {}) {
//   return Axios.post("/paymentApplication/affirmApply", cond)
// }
// 查看审核列表详情信息
export const getAuditDetailed = function (cond) {
  return Axios.post('/paymentApplication/getAuditDetailed/' + cond)
}
// //付款导出
// export const exporting = function(cond) {
//   return Axios({
//     url: "/paymentApplication/export/" + cond,
//     method: "get",
//     responseType: "blob",
//   })
// }
// ---------------------------------------------------------------------

// 提交
export const startProcess = function (cond) {
  return Axios.post('/projectPaymentApplication/startProcess', cond)
}

// 个人填报列表
export const getApplyForList = function (cond) {
  return Axios.post('/projectPaymentApplication/getApplyForList', cond)
}

// 查看详情
export const selectDetailById = function (cond) {
  return Axios.post('/projectPaymentApplication/selectDetailById/' + cond)
}

// 暂存编辑
export const undeterminedEdit = function (cond) {
  return Axios.post('/projectPaymentApplication/undeterminedEdit', cond)
}

// 暂存
export const tempAdd = function (cond) {
  return Axios.post('/projectPaymentApplication/add', cond)
}
// 删除
export const deleteExpense = function (cond) {
  return Axios.post('/projectPaymentApplication/delete', cond)
}
// 撤回
export const reject = function (cond) {
  return Axios.post('/projectPaymentApplication/reject', cond)
}
// projectCost
// 驳回提交
export const resubmitByReject = function (cond) {
  return Axios.post('/projectPaymentApplication/resubmitByReject', cond)
}

// 审批列表
export const searchTask = function (cond) {
  return Axios.post('/projectPaymentApplication/searchTask', cond)
}
// 审批不通过
export const approvalReject = function (cond) {
  return Axios.post('/projectPaymentApplication/approvalReject', cond)
}
// 审批通过
export const disposeTask = function (cond) {
  return Axios.post('/projectPaymentApplication/disposeTask', cond)
}

// 财务确认报销
export const affirmApply = function (cond) {
  return Axios.post('/projectPaymentApplication/affirmApply', cond)
}
//报销导出（新）
export const exporting = function (cond) {
  return Axios({
    url: '/projectPaymentApplication/export/' + cond,
    method: 'get',
    responseType: 'blob',
  })
}
//明细列表
export const projectPaymentApplicationAllList = function (cond) {
  return Axios.post('/projectPaymentApplication/projectPaymentApplicationAllList', cond)
}
//项目付款的统计值
export const getApplyStatistics = function (cond) {
  return Axios.post('/projectPaymentApplication/getApplyStatistics', cond)
}
// 项目付款申请明细导出
export const projectPaymentApplicationListExport = function (cond = {}) {
  return Axios({
    url: '/projectPaymentApplication/listExport',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}
