import Axios from '@/axios'

// 修改商务绩效分配列表
export const saveOrUpdatePerformance = function (cond = {}) {
  return Axios.post('/businessPerformance/saveOrUpdatePerformance', cond)
}

// 获取商务绩效分配列表
export const getAllocationList = function (cond = {}) {
  return Axios.post('/businessPerformance/getAllocationList', cond)
}

// 获取商务绩效审核列表
export const getAuditList = function (cond = {}) {
  return Axios.post('/businessPerformance/auditList', cond)
}

// 审核商务绩效
export const updateAudit = function (cond = {}) {
  return Axios.post('/businessPerformance/audit', cond)
}
// 获取商务绩效审核详情
export const getPerformanceInfo = function (cond) {
  return Axios.post('/businessPerformance/getPerformanceInfo/' + cond)
}

// 获取商务绩效核算详情
export const getCalculatePerformanceInfo = function (cond) {
  return Axios.post('/businessPerformance/getCalculatePerformanceInfo/' + cond)
}

// 提交商务绩效核算
export const saveOrUpdateCalculatePerformance = function (cond = {}) {
  return Axios.post('/businessPerformance/saveOrUpdateCalculatePerformance', cond)
}

// 商务绩效分配明细列表
export const allocationAllList = function (cond = {}) {
  return Axios.post('/businessPerformance/allocationAllList', cond)
}

// 个人绩效列表
export const allocationAllListToStaff = function (cond = {}) {
  return Axios.post('/businessPerformance/allocationAllListToStaff', cond)
}

// 获取商务绩效核算详情
export const getCalculatePerformanceInfoToStaff = function (cond) {
  return Axios.post('/businessPerformance/getCalculatePerformanceInfoToStaff/' + cond)
}

// 商务绩效分配的统计值
export const getApplyStatistics = function (cond = {}) {
  return Axios.post('/businessPerformance/getApplyStatistics', cond)
}

// 商务绩效概要（全部商务列表获取商务费用结算数据）
export const getCalculateBusinessByBusinessId = function (cond) {
  return Axios.post('/businessPerformance/getCalculateBusinessByBusinessId/' + cond)
}

// 商务绩效比例分配撤回
export const reject = function (cond = {}) {
  return Axios.post('/businessPerformance/reject', cond)
}

// 工作流接口
// 提交
export const startProcess = function (cond = {}) {
  return Axios.post('/businessPerformance/startProcess', cond)
}

// 个人填报列表
export const getApplyForList = function (cond = {}) {
  return Axios.post('/businessPerformance/getApplyForList', cond)
}

// 查看详情
export const selectDetailById = function (cond) {
  return Axios.post('/businessPerformance/selectDetailById/' + cond)
}

// 查看详情-商务id
export const selectDetailByBusinessId = function (cond) {
  return Axios.post('/businessPerformance/selectDetailByBusinessId/' + cond)
}

// 暂存编辑
export const undeterminedEdit = function (cond = {}) {
  return Axios.post('/businessPerformance/undeterminedEdit', cond)
}

// 暂存
export const tempAdd = function (cond = {}) {
  return Axios.post('/businessPerformance/add', cond)
}

// 删除
export const deleteExpense = function (cond = {}) {
  return Axios.post('/businessPerformance/delete', cond)
}
// 驳回提交
export const resubmitByReject = function (cond = {}) {
  return Axios.post('/businessPerformance/resubmitByReject', cond)
}

// 审批列表
export const searchTask = function (cond = {}) {
  return Axios.post('/businessPerformance/searchTask', cond)
}
// 审批不通过
export const approvalReject = function (cond = {}) {
  return Axios.post('/businessPerformance/approvalReject', cond)
}
// 审批通过
export const disposeTask = function (cond = {}) {
  return Axios.post('/businessPerformance/disposeTask', cond)
}

//明细列表
export const allList = function (cond = {}) {
  return Axios.post('/businessPerformance/allList', cond)
}

//商务结算调整-获取相应商务人员比例
export const selectBusinessPerformanceInfoByBusinessId = function (cond) {
  return Axios.post('/businessPerformance/selectBusinessPerformanceInfoByBusinessId/' + cond)
}
