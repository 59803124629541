import Axios from '@/axios'

// 生产绩效分配方案统计值
export const getApplyStatistics = function () {
  return Axios.post('/projectPerformance/getApplyStatistics')
}

// 生产绩效分配方案填报列表
export const getApplyForList = function (cond = {}) {
  return Axios.post('/projectPerformance/getApplyForList', cond)
}

// 生产绩效分配方案审核列表
export const searchTask = function (cond = {}) {
  return Axios.post('/projectPerformance/searchTask', cond)
}

// 生产绩效分配方案明细列表
export const allList = function (cond = {}) {
  return Axios.post('/projectPerformance/allList', cond)
}

// 生产绩效分配方案暂存
export const add = function (cond = {}) {
  return Axios.post('/projectPerformance/add', cond)
}

// 生产绩效分配方案提交
export const startProcess = function (cond = {}) {
  return Axios.post('/projectPerformance/startProcess', cond)
}

// 生产绩效分配方案暂存编辑
export const undeterminedEdit = function (cond = {}) {
  return Axios.post('/projectPerformance/undeterminedEdit', cond)
}

// 生产绩效分配方案撤回
export const reject = function (cond = {}) {
  return Axios.post('/projectPerformance/reject', cond)
}

// 生产绩效分配方案重新提交
export const resubmitByReject = function (cond = {}) {
  return Axios.post('/projectPerformance/resubmitByReject', cond)
}

// 生产绩效详情
export const selectDetailById = function (cond = {}) {
  return Axios.post('/projectPerformance/selectDetailById/' + cond)
}

// 生产绩效审核不通过
export const approvalReject = function (cond = {}) {
  return Axios.post('/projectPerformance/approvalReject', cond)
}

// 生产绩效审核通过
export const disposeTask = function (cond = {}) {
  return Axios.post('/projectPerformance/disposeTask', cond)
}

// 生产绩效导出
export const exportById = function (cond = {}) {
  return Axios.get('/projectPerformance/export/' + cond)
}

// 通过项目id查找项目详情信息，用于填报新的绩效分配方案
export const selectDetailByProjectId = function (cond = {}) {
  return Axios.post('/projectPerformance/selectDetailByProjectId/' + cond)
}

// 员工本人绩效列表
export const getListToStaff = function (cond = {}) {
  return Axios.post('/projectPerformance/getListToStaff', cond)
}

// 本人绩效详情
export const selectDetailByIdToStaff = function (cond = {}) {
  return Axios.post('/projectPerformance/selectDetailByIdToStaff/' + cond)
}
