import Axios from "@/axios";
import { Message } from "element-ui";
import store from "@/store";

// 规则列表
export const limitConfigurationList = function(cond = {}) {
  return Axios.post("/limitConfiguration/list", cond);
};

// 编辑规则
export const limitConfigurationEdit = function(cond = {}) {
  return Axios.post("/limitConfiguration/edit", cond);
};

// 规则变更列表
export const limitConfigurationLogList = function(cond = {}) {
  return Axios.post("/limitConfiguration/logList", cond);
};

// 规则变更明细
export const selectLogDetailById = function(id) {
  return Axios.post("/limitConfiguration/selectLogDetailById/" + id);
};
// 添加规则
export const limitConfigurationAdd = function(cond = {}) {
  return Axios.post("/limitConfiguration/add" , cond);
};
