import Axios from '@/axios'

//获取外协列表数据
export const getOutsourceData = function(cond = {}) {
  return Axios.post('/outsourceCompany/list', cond)
}

//根据id获取外协详细信息
export const getDetailOutsourceData = function(cond = {}) {
  return Axios.post('/outsourceCompany/selectById/' + cond)
}

// 创建外协公司信息
export const addOutsourceData = function(cond = {}) {
  return Axios.post('/outsourceCompany/add', cond)
}

// 编辑修改外协信息
export const editOutsourceData = function(cond = {}) {
  return Axios.post('/outsourceCompany/edit', cond)
}

// 删除外协信息
export const deleteOutsourceData = function(cond = {}) {
  return Axios.post('/outsourceCompany/delete/' + cond)
}
