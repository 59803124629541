import Axios from '@/axios'

// 提交
export const startProcess = function (cond) {
  return Axios.post('/publicSpendingPaymentApplication/startProcess', cond)
}

// 个人填报列表
export const getApplyForList = function (cond) {
  return Axios.post('/publicSpendingPaymentApplication/getApplyForList', cond)
}

// 查看详情
export const selectDetailById = function (cond) {
  return Axios.post('/publicSpendingPaymentApplication/selectDetailById/' + cond)
}

// 暂存编辑
export const undeterminedEdit = function (cond) {
  return Axios.post('/publicSpendingPaymentApplication/undeterminedEdit', cond)
}

// 暂存
export const tempAdd = function (cond) {
  return Axios.post('/publicSpendingPaymentApplication/add', cond)
}
// 删除
export const deleteExpense = function (cond) {
  return Axios.post('/publicSpendingPaymentApplication/delete', cond)
}
// 撤回
export const reject = function (cond) {
  return Axios.post('/publicSpendingPaymentApplication/reject', cond)
}
// projectCost
// 驳回提交
export const resubmitByReject = function (cond) {
  return Axios.post('/publicSpendingPaymentApplication/resubmitByReject', cond)
}

// 审批列表
export const searchTask = function (cond) {
  return Axios.post('/publicSpendingPaymentApplication/searchTask', cond)
}
// 审批不通过
export const approvalReject = function (cond) {
  return Axios.post('/publicSpendingPaymentApplication/approvalReject', cond)
}
// 审批通过
export const disposeTask = function (cond) {
  return Axios.post('/publicSpendingPaymentApplication/disposeTask', cond)
}

// 财务确认报销
export const affirmApply = function (cond) {
  return Axios.post('/publicSpendingPaymentApplication/affirmApply', cond)
}
//报销导出（新）
export const exporting = function (cond) {
  return Axios({
    url: '/publicSpendingPaymentApplication/export/' + cond,
    method: 'get',
    responseType: 'blob',
  })
}
//明细列表
export const publicSpendingPaymentApplicationAllList = function (cond) {
  return Axios.post(
    '/publicSpendingPaymentApplication/publicSpendingPaymentApplicationAllList',
    cond
  )
}
//公共费用付款申请的统计值
export const getApplyStatistics = function (cond) {
  return Axios.post('/publicSpendingPaymentApplication/getApplyStatistics', cond)
}
// 公共费用付款申请明细导出
export const publicSpendingPaymentApplicationListExport = function (cond = {}) {
  return Axios({
    url: '/publicSpendingPaymentApplication/listExport',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}
