import Axios from '@/axios'

// 获取合作方数据列表
export const getPartnerCompanyDataList = function (cond = {}) {
  return Axios.post('/cooperativeCorporation/list', cond)
}

// 删除合作方公司数据
export const deletePartnerCompanyData = function (cond = {}) {
  return Axios.post('/cooperativeCorporation/delete/' + cond)
}

// 获取当前合作方详情信息
export const getDetailPartnerCompanyData = function (cond = {}) {
  return Axios.post('/cooperativeCorporation/selectById/' + cond)
}

// 添加合作方信息
export const addPartnerCompanyData = function (cond = {}) {
  return Axios.post('/cooperativeCorporation/add', cond)
}

// 编辑合作方信息
export const updatePartnerCompanyData = function (cond = {}) {
  return Axios.post('/cooperativeCorporation/edit', cond)
}

// 获取合作方不分页
export const getPartnerCompanyDataListNoPage = function (cond = {}) {
  return Axios.post('/cooperativeCorporation/listNoPage', cond)
}
