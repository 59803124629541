const user = {
  namespaced: true,
  state: {
    userInfo: localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo'))
      : { buttonCodeList: [] },
    menus: [],
    loadMenus: false,
    selectMenu: {}, //当前选中的二级菜单
    backlogIsShow: false, //首页待办
  },
  mutations: {
    SET_USER_INFO: (state, payload) => {
      if (payload.userInfo) {
        localStorage.setItem('userInfo', JSON.stringify(payload.userInfo))
        state.userInfo = payload.userInfo
      }
    },
    SET_MENUS: (state, payload) => {
      state.menus = payload.menus
      state.selectMenu = {}
    },
    SET_BACK_LOG_SHOW: (state, payload) => {
      state.backlogIsShow = payload.backlogIsShow
    },
    SET_LOAD_MENUS: (state, payload) => {
      state.loadMenus = payload.loadMenus
    },
    SIGN_OUT: state => {
      state.userInfo = {}
      state.roles = []
      state.menus = []
      state.loadMenus = false
      state.backlogIsShow = false
      localStorage.removeItem('userInfo')
    },
    SET_SELECT_MENU: (state, menu) => {
      state.selectMenu = menu
    },
    //清除所有菜单高亮
    CLEAR_MENUS_ACTIVE: state => {
      // 定义递归函数
      const deactivateNode = node => {
        node.isActive = false
        if (node.children) {
          node.children.forEach(child => {
            deactivateNode(child) // 递归调用，处理子节点
          })
        }
      }
      //调用递归函数
      state.menus.forEach(rootNode => {
        deactivateNode(rootNode)
      })
    },
  },
  actions: {
    setUserInfo({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        commit('SET_USER_INFO', { userInfo })
        resolve()
      })
    },
    setLoadMenus({ commit }, loadMenus) {
      return new Promise((resolve, reject) => {
        commit('SET_LOAD_MENUS', { loadMenus })
        resolve()
      })
    },
    getUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        resolve(this.state.user.userInfo)
      })
    },
  },
}

export default user
