import Axios from '@/axios'

// //查看商务报销详情
export const getProjectExpense = function (cond) {
  return Axios.post('/businessExpense/getProjectExpense/' + cond)
}

//删除商务报销单
export const removeProjectCost = function (cond) {
  return Axios.post('/businessExpense/removeProjectCost/' + cond)
}

//获取商务审核列表
export const getAuditList = function (cond = {}) {
  return Axios.post('/businessExpense/getAuditList', cond)
}

//创建商务报销
export const addProjectCost = function (cond = {}) {
  return Axios.post('/businessExpense/addProjectCost', cond)
}

//商务报销审核
export const auditProjectCost = function (cond = {}) {
  return Axios.post('/businessExpense/auditProjectCost', cond)
}

// //商务费用确认报销
// export const affirmApply = function(cond) {
//   return Axios.post('/businessExpense/affirmApply', cond)
// }

//获取商务报销通过列表
export const auditPassList = function (cond = {}) {
  return Axios.post('/businessExpense/auditPassList', cond)
}

// //获取商务报销列表统计值
// export const getApplyStatistics = function(cond = {}) {
//   return Axios.post('/businessExpense/getApplyStatistics', cond)
// }

//获取商务报销列表统计值
export const projectList = function (cond = {}) {
  return Axios.post('/businessExpense/projectList', cond)
}

// //商务报销导出
// export const exporting = function(cond) {
//   return Axios({
//     url: '/businessExpense/export/' + cond,
//     method: 'get',
//     responseType: 'blob',
//   })
// }

//获取商务总费用列表
export const list = function (cond = {}) {
  return Axios.post('/businessExpense/list', cond)
}

//商务报销撤回
// export const reject = function(cond = {}) {
//   return Axios.post('/businessExpense/reject', cond)
// }

/******************************************************************************************************/

//获取能报销的商务列表
export const getBusinessNameList = function (cond = {}) {
  return Axios.post('/businessExpense/getBusinessNameList', cond)
}

//获取该用户有权限得商务列表
export const businessNameList = function (cond = {}) {
  return Axios.post('/businessExpense/businessNameList', cond)
}

// 提交
export const startProcess = function (cond) {
  return Axios.post('/businessExpense/startProcess', cond)
}

// 个人填报列表
export const getApplyForList = function (cond) {
  return Axios.post('/businessExpense/getApplyForList', cond)
}

// 查看详情
export const selectDetailById = function (cond) {
  return Axios.post('/businessExpense/selectDetailById/' + cond)
}

// 暂存编辑
export const undeterminedEdit = function (cond) {
  return Axios.post('/businessExpense/undeterminedEdit', cond)
}

// 暂存
export const tempAdd = function (cond) {
  return Axios.post('/businessExpense/add', cond)
}
// 删除
export const deleteExpense = function (cond) {
  return Axios.post('/businessExpense/delete', cond)
}
// 撤回
export const reject = function (cond) {
  return Axios.post('/businessExpense/reject', cond)
}

// 驳回提交
export const resubmitByReject = function (cond) {
  return Axios.post('/businessExpense/resubmitByReject', cond)
}

// 审批列表
export const searchTask = function (cond) {
  return Axios.post('/businessExpense/searchTask', cond)
}
// 审批不通过
export const approvalReject = function (cond) {
  return Axios.post('/businessExpense/approvalReject', cond)
}
// 审批通过
export const disposeTask = function (cond) {
  return Axios.post('/businessExpense/disposeTask', cond)
}

// 财务确认报销
export const affirmApply = function (cond) {
  return Axios.post('/businessExpense/affirmApply', cond)
}
//商务报销导出
export const exporting = function (cond) {
  return Axios({
    url: '/businessExpense/export/' + cond,
    method: 'get',
    responseType: 'blob',
  })
}
//商务费用明细列表
export const businessExpenseAllList = function (cond) {
  return Axios.post('/businessExpense/businessExpenseAllList', cond)
}
//商务的统计值
export const getApplyStatistics = function (cond) {
  return Axios.post('/businessExpense/getApplyStatistics', cond)
}

// 商务报销根据商务汇总（用于商务绩效核算页面）
export const listGroupBusinessOrStaff = function (cond) {
  return Axios.post('/businessExpense/listGroupBusinessOrStaff', cond)
}

// 商务报销用户明细列表（用于商务绩效核算页面）
export const businessExpenseListToStaff = function (cond) {
  return Axios.post('/businessExpense/businessExpenseListToStaff', cond)
}

// 商务报销明细导出
export const businessExpenseListExport = function (cond) {
  return Axios({
    url: '/businessExpense/listExport',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}
