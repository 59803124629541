import Axios from '@/axios'
import { Message } from 'element-ui'
import store from '@/store'

export const add = function (cond = {}) {
  return Axios.post('/sysDictData/add', cond)
}

export const edit = function (cond = {}) {
  return Axios.post('/sysDictData/edit', cond)
}

export const del = function (cond) {
  return Axios.post('/sysDictData/delete/' + cond)
}

export const getTreeDataList = function (cond = {}) {
  return Axios.post('/sysDictData/getTreeDataList', cond)
}

export const listSysDictData = function (code, cache = false) {
  return new Promise((resolve, reject) => {
    if (cache && store.state.dict.data[code]) {
      resolve(store.state.dict.data[code])
    } else {
      Axios.post('/sysDictData/listSysDictData', {
        dictType: code,
      })
        .then(res => {
          store.commit({
            type: 'dict/SET_DATA',
            data: res,
            code,
          })
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    }
  })
}
