import Axios from '@/axios'
// 提交
export const startProcess = function (cond = {}) {
  return Axios.post('/expenditureAdvanceReturn/startProcess', cond)
}

// 个人填报列表
export const getApplyForList = function (cond = {}) {
  return Axios.post('/expenditureAdvanceReturn/getApplyForList', cond)
}

// 查看详情
export const selectDetailById = function (cond) {
  return Axios.post('/expenditureAdvanceReturn/selectDetailById/' + cond)
}

// 暂存编辑
export const undeterminedEdit = function (cond = {}) {
  return Axios.post('/expenditureAdvanceReturn/undeterminedEdit', cond)
}

// 暂存
export const tempAdd = function (cond = {}) {
  return Axios.post('/expenditureAdvanceReturn/add', cond)
}
// 删除
export const deleteExpense = function (cond = {}) {
  return Axios.post('/expenditureAdvanceReturn/delete', cond)
}
// 撤回
export const reject = function (cond = {}) {
  return Axios.post('/expenditureAdvanceReturn/reject', cond)
}
// 驳回提交
export const resubmitByReject = function (cond = {}) {
  return Axios.post('/expenditureAdvanceReturn/resubmitByReject', cond)
}

// 审批列表
export const searchTask = function (cond = {}) {
  return Axios.post('/expenditureAdvanceReturn/searchTask', cond)
}
// 审批不通过
export const approvalReject = function (cond = {}) {
  return Axios.post('/expenditureAdvanceReturn/approvalReject', cond)
}
// 审批通过
export const disposeTask = function (cond = {}) {
  return Axios.post('/expenditureAdvanceReturn/disposeTask', cond)
}

//明细列表
export const fundsGiveBackAllList = function (cond = {}) {
  return Axios.post('/expenditureAdvanceReturn/allList', cond)
}
//统计值
export const getApplyStatistics = function (cond = {}) {
  return Axios.post('/expenditureAdvanceReturn/getApplyStatistics', cond)
}

//导出
export const exporting = function (cond) {
  return Axios({
    url: '/expenditureAdvanceReturn/export/' + cond,
    method: 'get',
    responseType: 'blob',
  })
}
