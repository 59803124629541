import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'



import './prototype.js'
import './filter.js'

import api from './api/index'
Vue.prototype.$api = api;


//自定义控件挂载
Vue.config.productionTip = false

import ElementUI from 'element-ui';
import './styles/icon/iconfont.css';
import './styles/element-variables.scss';
import 'element-ui/lib/theme-chalk/index.css';
import "./elementui.js"
Vue.use(ElementUI);

// 1. 全局挂载
import Print from 'vue-print-nb'
Vue.use(Print)
 
// or
 
// 2. 自定义指令
import print from 'vue-print-nb'
directives: {
  print
}

import "./styles/common.scss"


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')



