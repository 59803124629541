import Axios from '@/axios'

// 获取商务支出列表按人员划分（用于绩效核算）
export const listGroupBusinessOrStaff = function (cond = {}) {
  return Axios.post('/businessTally/listGroupBusinessOrStaff', cond)
}

// 获取商务支出人员明细
export const listToStaff = function (cond = {}) {
  return Axios.post('/businessTally/listToStaff', cond)
}

// 本人记账汇总列表
export const listOnlyGroupBusiness = function (cond = {}) {
  return Axios.post('/businessTally/listOnlyGroupBusiness', cond)
}

// 个人记账汇总明细列表
export const listCollectGroupBusiness = function (cond = {}) {
  return Axios.post('/businessTally/listCollectGroupBusiness', cond)
}

// 商务记账个人额度
export const onlyGroupQuota = function (cond = {}) {
  return Axios.post('/businessTally/onlyGroupQuota', cond)
}
