import Axios from '@/axios'
import {Message} from "element-ui";


//编辑获奖信息
export const updateAwards = function (cond={}) {
  return Axios.post('/certificationManage/updateAwards', cond);
}

//添加获奖信息
export const addAwards = function (cond={}) {
  return Axios.post('/certificationManage/addAwards', cond);
}

// 查看获奖信息列表
export const getAwards = function (cond={}) {
  return Axios.post('/certificationManage/getAwards', cond);
}

//上传附件
export const uploadInterviewFile = function (cond={}) {
  return Axios.post('/rsUserInfoController/uploadInterviewFile', cond);
}









