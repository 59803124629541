import Vue from 'vue'
import store from '@/store'
import { pinyinUtil } from './util/pinyinUtil.js'

// 权限判断
Vue.prototype.permission = function(value) {
  // if (process.env.NODE_ENV == "development") {
  //   //如果是dev环境
  //   return true;
  // }

  const userInfo = store.state.user.userInfo
  const codes = userInfo.buttonCodeList
  // if (userInfo.loginName == "admin") {
  //   return true;
  // }
  if (value && value instanceof Array && value.length > 0) {
    const permissionRoles = value
    const hasPermission = codes?.some(code => {
      return permissionRoles.includes(code)
    })
    return hasPermission
  } else {
    return true
  }
}

Vue.prototype.scrollView = function(obj) {
  for (const i in obj) {
    var ref = this.$refs[i]
    if (Object.prototype.toString.call(ref) === '[object Array]') {
      var dom = ref[0]
    } else {
      dom = ref
    }
    dom?.$el.scrollIntoView({ block: 'center', behavior: 'smooth' })
    break
  }
}

//对象深度克隆  注意这里不能用prototype 详见https://www.freesion.com/article/688475984/
Object.defineProperty(Object.prototype, 'deepClone', {
  value: function() {
    let newObj = Array.isArray(this) ? [] : {}
    if (this && typeof this === 'object') {
      for (let key in this) {
        if (this.hasOwnProperty(key)) {
          newObj[key] =
            this && this[key] && typeof this[key] === 'object' ? this[key].deepClone() : this[key]
        }
      }
    }
    return newObj
  },
})

//对象深度合并
Object.defineProperty(Object.prototype, 'combination', {
  value: function(obj) {
    for (let key in obj) {
      if (obj[key] && typeof obj[key] === 'object') {
        if (!this[key]) {
          this[key] = Array.isArray(obj[key]) ? [] : {}
        }
        this[key].combination(obj[key])
      } else {
        this[key] = obj[key]
      }
    }
    return this
  },
})

//日期格式转换
Date.prototype.format = function(fmt) {
  var weekday = ['日', '一', '二', '三', '四', '五', '六']
  var o = {
    'M+': this.getMonth() + 1, //月份
    'd+': this.getDate(), //日
    'H+': this.getHours(), //小时
    'h+': this.getHours() % 24, //小时
    'm+': this.getMinutes(), //分
    's+': this.getSeconds(), //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
    w: weekday[this.getDay()], //星期
    I: this.getDay(), //星期下标
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      )
    }
  }
  return fmt
}

//日期加减
Date.prototype.calculation = function(days) {
  var now = new Date(this.getTime())
  now.setDate(now.getDate() + days)
  return now
}

//获取当前月第一天
Date.prototype.getMonthStar = function() {
  var nowMonth = this.getMonth() //当前月
  var nowYear = this.getFullYear() //当前年
  return new Date(nowYear, nowMonth, 1)
}
//获取当前月最后一天
Date.prototype.getMonthEnd = function() {
  var nowMonth = this.getMonth() //当前月
  var nowYear = this.getFullYear() //当前年
  return new Date(nowYear, nowMonth + 1, 0)
}

//获取当前星期第一天
Date.prototype.getWeekStar = function() {
  var w = this.getDay()
  return w ? this.calculation(1 - w) : this.calculation(-6)
}
//获取当前星期最后一天
Date.prototype.getWeekEnd = function() {
  var w = this.getDay()
  return w ? this.calculation(7 - w) : this
}

//获取文件后缀名 如果有str2 参数 则判断后缀名是否等于str2
String.prototype.getSuffix = function(str2) {
  var index = this.lastIndexOf('.')
  var suffix = this.substr(index + 1)
  if (str2) {
    return str2.toUpperCase() == suffix.toUpperCase()
  } else {
    return suffix
  }
}

//汉子转拼音
String.prototype.getPinyin = function(a = '', b = false, c = false) {
  if (this) {
    return pinyinUtil.getPinyin(this, a, b, c)
  } else {
    return ''
  }
}
