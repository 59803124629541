import Axios from '@/axios'
import { Message } from 'element-ui'

// 添加部门
export const add = function (cond = {}) {
  return Axios.post('/sysDept/add', cond)
}

// 删除部门
export const del = function (cond) {
  return Axios.post('/sysDept/delete/' + cond)
}

//编辑部门
export const edit = function (cond = {}) {
  return Axios.post('/sysDept/edit', cond)
}

//部门列表
export const listDept = function (cond = {}) {
  return Axios.post('/sysDept/listDept', cond)
}

//查有员工的部门列表
export const getDeptNameList = function (cond = {}) {
  return Axios.post('/sysDept/getDeptNameList', cond)
}

// 部门树形结构
export const getTreeDept = function (cond = {}) {
  return Axios.post('/sysDept/getTreeDept', cond)
}

// 查询用户所属部门
export const queryStaffDept = function (cond = {}) {
  return Axios.post('/sysStaff/queryStaffDept', cond)
}

// 获取当前用户所在部门树形结构
export const getUserDeptTree = function (cond) {
  return Axios.post('/sysDept/getUserDeptTree/' + cond)
}
