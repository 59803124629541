import api from '@/api/index'
import { backlogList } from '@/util/regular'
import store from '@/store'
// 加密
export function encrypt(txt) {
  if (txt != '' && txt != null) {
    return window.btoa(txt.toString()) // 对数据进行加密
  }
  return ''
}

// 解密
export function decrypt(txt) {
  if (txt != '') {
    return window.atob(txt.toString()) // 对数据进行解密
  }
  return ''
}

const MoneyTest = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
// 金额添加千分位
const comdify = function (n) {
  if (!n) return n
  let str = n.split('.')
  let re = /\d{1,3}(?=(\d{3})+$)/g
  let n1 = str[0].replace(re, '$&,')
  return str.length > 1 && str[1] ? `${n1}.${str[1]}` : `${n1}`
  // return str.length > 1 && str[1] ? `${n1}.${str[1]}` : `${n1}.00`;
}

//去除千分位中的‘，'
export function delcommafy(num) {
  if (!num) return num
  num = num.toString()
  num = num.replace(/,/gi, '')
  return num
}
const valdateFn = function (rule, val, cb) {
  setTimeout(() => {
    if (val) {
      let inputVal = delcommafy(val)
      if (rule.test(inputVal)) {
        cb()
      } else {
        cb('只能是数字金额,最多两位小数')
      }
    }
    cb()
  })
}
// 验证金额数字可以为负数
const moneyValid = function (rule, val, cb) {
  valdateFn(/((^-?[1-9]\d*)|^-?0)(\.\d{0,2}){0,1}$/, val, cb)
}
// 验证金额数字不可以为负数
const moneyNValid = function (rule, val, cb) {
  valdateFn(MoneyTest, val, cb)
}
// 获取输入框的值
export function getInputValue(el) {
  // let inputVal = el;
  let inputVal = el.target.value || ''
  let i = inputVal.replace(/[\u4E00-\u9FA5]/g, '')
  return comdify(delcommafy(i))
}

//金额改为千分位
export function numberToCurrencyNo(value) {
  if (!value) return 0
  // 获取整数部分
  const intPart = Math.trunc(value)
  // 整数部分处理，增加,
  const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  // 预定义小数部分
  let floatPart = ''
  // 将数值截取为小数部分和整数部分
  const valueArray = value.toString().split('.')
  if (valueArray.length === 2) {
    // 有小数部分
    floatPart = valueArray[1].toString() // 取得小数部分
    return intPartFormat + '.' + floatPart
  }
  return intPartFormat + floatPart
}

// 输入金额转为中文大写方法
export const changeMoneyToChinese = function (money) {
  if (money) {
    //汉字的数字
    var cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
    //基本单位
    var cnIntRadice = ['', '拾', '佰', '仟']
    //对应整数部分扩展单位
    var cnIntUnits = ['', '万', '亿', '兆']
    //对应小数部分单位
    var cnDecUnits = ['角', '分', '毫', '厘']
    //整数金额时后面跟的字符
    var cnInteger = '整'
    //整型完以后的单位
    var cnIntLast = '圆'
    //最大处理的数字
    var maxNum = 999999999999999.9999
    //金额整数部分
    var integerNum
    //金额小数部分
    var decimalNum
    //输出的中文金额字符串
    var chineseStr = ''
    //分离金额后用的数组，预定义
    var parts
    if (money == '') {
      return ''
    }
    money = parseFloat(money)
    if (money >= maxNum) {
      //超出最大处理数字
      return ''
    }
    if (money == 0) {
      chineseStr = cnNums[0] + cnIntLast + cnInteger
      return chineseStr
    }
    //转换为字符串
    money = money.toString()
    if (money.indexOf('.') == -1) {
      integerNum = money
      decimalNum = ''
    } else {
      parts = money.split('.')
      integerNum = parts[0]
      decimalNum = parts[1].substr(0, 4)
    }
    //获取整型部分转换
    if (parseInt(integerNum, 10) > 0) {
      var zeroCount = 0
      var IntLen = integerNum.length
      for (var i = 0; i < IntLen; i++) {
        var n = integerNum.substr(i, 1)
        var p = IntLen - i - 1
        var q = p / 4
        var m = p % 4
        if (n == '0') {
          zeroCount++
        } else {
          if (zeroCount > 0) {
            chineseStr += cnNums[0]
          }
          //归零
          zeroCount = 0
          chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
        }
        if (m == 0 && zeroCount < 4) {
          chineseStr += cnIntUnits[q]
        }
      }
      chineseStr += cnIntLast
    }
    //小数部分
    if (decimalNum != '') {
      var decLen = decimalNum.length
      for (var i = 0; i < decLen; i++) {
        var n = decimalNum.substr(i, 1)
        if (n != '0') {
          chineseStr += cnNums[Number(n)] + cnDecUnits[i]
        }
      }
    }
    if (chineseStr == '') {
      chineseStr += cnNums[0] + cnIntLast + cnInteger
    } else if (decimalNum == '') {
      chineseStr += cnInteger
    }
    return chineseStr
  } else {
    return '零'
  }
}

// 获取待办-赋值红点提示
export const getBacklogFn = async function (data) {
  let obj = {
    undetermined: 0,
    notapplyconfirm: 0,
    notpayed: 0,
    menus: [],
  }
  let show = false

  let undetermined = await api.backlog.getActTransferList({
    approvalType: 'UNDETERMINED',
  })

  let notapplyconfirm = await api.backlog.getActTransferList({
    approvalType: 'NOT_APPLY_CONFIRM',
  })
  let notpayed = {}
  // 按钮权限判断
  if (store._modules.root.state.user.userInfo.buttonCodeList.some(v => v === 'BACKLOG_QRDB')) {
    notpayed = await api.backlog.getActTransferList({
      approvalType: 'NOT_PAYED',
    })
  }
  let one = []
  if (undetermined) {
    one = undetermined.data.records.map(v => {
      return { ...v, key: 'UNDETERMINED' }
    })
    undetermined.data.records.forEach(v => (obj.undetermined += Number(v.sumQuantity)))
  }
  let two = []
  if (notapplyconfirm) {
    two = notapplyconfirm.data.records.map(v => {
      return { ...v, key: 'NOT_APPLY_CONFIRM' }
    })
    notapplyconfirm.data.records.forEach(v => (obj.notapplyconfirm += Number(v.sumQuantity)))
  }
  let three = []
  if (notpayed.data) {
    three = notpayed.data.records.map(v => {
      return { ...v, key: 'NOT_PAYED' }
    })
    notpayed.data.records.forEach(v => (obj.notpayed += Number(v.sumQuantity)))
  }

  // 红点赋值
  let arrAll = [...one, ...two, ...three]
  let arr = []
  backlogList.forEach(v => {
    arrAll.forEach(item => {
      if (v.actkey === item.actkey) {
        arr.push({
          name: item.name,
          sumQuantity: item.sumQuantity,
          examineCode: v.examineCode,
          passCosCode: v.passCosCode,
          key: item.key,
        })
      }
    })
  })
  if (data && data.length > 0) {
    arr.forEach(v => {
      data.forEach(item => {
        if (v.key === 'NOT_PAYED' && v.passCosCode === item.code && v.sumQuantity > 0) {
          item.backlog = true
        } else if (v.key === 'UNDETERMINED' && v.examineCode === item.code && v.sumQuantity > 0) {
          item.backlog = true
        } else if (
          v.key === 'NOT_APPLY_CONFIRM' &&
          v.examineCode === item.code &&
          v.sumQuantity > 0
        ) {
          item.backlog = true
        }
      })
    })
    obj.menus = data
  }

  // 首页显示待办按钮
  if (obj.undetermined > 0 || obj.notapplyconfirm > 0 || obj.notpayed > 0) {
    show = true
  } else {
    show = false
  }

  store.commit({
    type: 'user/SET_BACK_LOG_SHOW',
    backlogIsShow: show,
  })
  return obj
}

/*
actualPerformance：绩效总额
arr：绩效分配比例数组，包含人员
ifno：商务信息，主要取商务ID
*/
// 商务绩效-根据比例分配对应人员绩效金额
export const setBusinessPerformance = async function (actualPerformance, arr, ifno) {
  const performanceInfo = {
    businessManageId: ifno.id,
    actualPerformance: actualPerformance,
    businessPerformanceList: [],
    userAssignmentMap: [],
  }
  // 是编制部门并且是金额 先从总绩效扣除
  const Consultbiddingscheme = arr.find(
    item => item.ruleCode === 'CONSULT_BIDDING_SCHEME' && item.contentType === 'MONEY_TYPE'
  )
  if (Consultbiddingscheme && Consultbiddingscheme.money > 0) {
    actualPerformance = Number(actualPerformance) - Number(Consultbiddingscheme.money)
  }
  // 人员分配总额
  let sum = 0
  arr.forEach(v => {
    // 细项金额
    // 如果是比例
    if (v.contentType === 'RATIO_TYPE') {
      v.nowPerformanceValue = v.ratio * Number(actualPerformance) * 0.01
    } else {
      v.nowPerformanceValue = v.money
    }
    // 人员金额
    v.userAssignment.forEach(userItem => {
      let perValue = 0 //计算当前员工绩效
      if (userItem.contentType == 'RATIO_TYPE') {
        perValue = userItem.userRatio * Number(actualPerformance) * 0.01
        if (perValue !== 0) {
          perValue = Number(perValue.toFixed(2))
        }
      } else {
        perValue = Number(userItem.userMoney)
      }
      sum += perValue
      userItem.performanceValue = perValue
      userItem.nowperformanceValue = perValue
      userItem.nowUserRatio = Number(userItem.userRatio)
      userItem.nowUserMoney = Number(userItem.userMoney)
      performanceInfo.userAssignmentMap.push(userItem)
    })
  })

  arr.forEach(v => {
    // 信息提供人-是金额从公司利润扣除
    if (v.ruleCode === 'PROVIDE_INFORMATION' && v.contentType === 'MONEY_TYPE') {
      const Other = arr.find(item => item.ruleCode === 'OTHER')
      if (Other) {
        Other.nowPerformanceValue = Number(Other.nowPerformanceValue) - Number(v.money)
      }
    }
    performanceInfo.businessPerformanceList.push(v)
  })
  // 公司没有人员，所以取公司总额
  const other = arr.find(item => item.ruleCode === 'OTHER')
  if (other) {
    sum += Number(other.nowPerformanceValue)
  }
  sum = Number(sum.toFixed(2))
  // 如果人员分配总额与绩效总额有差额，那么在负责人身上加减
  // performanceInfo.actualPerformance为一开始没有扣除的总额
  if (sum !== performanceInfo.actualPerformance) {
    const num = Number(performanceInfo.actualPerformance) - Number(sum)
    const Leadcommunication = arr.find(item => item.ruleCode === 'LEAD_COMMUNICATION')
    if (Leadcommunication) {
      Leadcommunication.nowPerformanceValue += Number(num)
    }
  }

  const res = await api.businessPerformance.saveOrUpdateCalculatePerformance(performanceInfo)
}
