import Axios from "@/axios"
// 填报列表
export const getApplyForList = function(cond = {}) {
  return Axios.post("/registrationExpenditure/getApplyForList", cond)
}
// 确认列表
export const searchTask = function(cond = {}) {
  return Axios.post("/registrationExpenditure/searchTask", cond)
}
// 明细列表 register
export const registrationExpenditureAllList = function(cond = {}) {
  return Axios.post("/registrationExpenditure/registrationExpenditureAllList", cond)
}
// 提交
export const startProcess = function(cond = {}) {
  return Axios.post("/registrationExpenditure/startProcess", cond)
}
// 暂存
export const tempAdd = function(cond = {}) {
  return Axios.post("/registrationExpenditure/add", cond)
}
// 暂存编辑
export const undeterminedEdit = function(cond = {}) {
  return Axios.post("/registrationExpenditure/undeterminedEdit", cond)
}
// 删除
export const deleteRegister = function(cond = {}) {
  return Axios.post("/registrationExpenditure/delete", cond)
}
// 撤回
export const reject = function(cond = {}) {
  return Axios.post("/registrationExpenditure/reject", cond)
}
// 确认
export const confirm = function(cond = {}) {
  return Axios.post("/registrationExpenditure/confirm", cond)
}
// 驳回提交/重新提交
export const resubmitByReject = function(cond = {}) {
  return Axios.post("/registrationExpenditure/resubmitByReject", cond)
}
// 审批不通过
export const approvalReject = function(cond = {}) {
  return Axios.post("/registrationExpenditure/approvalReject", cond)
}
// 审批通过
export const disposeTask = function(cond = {}) {
  return Axios.post("/registrationExpenditure/disposeTask", cond)
}
// 统计值
export const getApplyStatistics = function(cond = {}) {
  return Axios.post("/registrationExpenditure/getApplyStatistics", cond)
}
// 查看详情
export const selectDetailById = function(cond ) {
  return Axios.post("/registrationExpenditure/selectDetailById/"+ cond)
}
// 支出登记导出
export const listExport = function(cond ) {
  return Axios({
    url: '/registrationExpenditure/listExport',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}

