import Axios from '@/axios'
import { Message } from 'element-ui'
import store from '@/store'

//商务记账规则列表
export const list = function(cond = {}) {
  return Axios.post('/businessLimitRule/list', cond)
}
//商务记账规则保存
export const edit = function(cond = {}) {
  return Axios.post('/businessLimitRule/edit', cond)
}

