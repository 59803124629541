import Axios from '@/axios'

// 项目列表用户排序
export const update = function (cond = {}) {
  return Axios.post('/projectMemberSort/update', cond)
}
//获取用户排序列表
export const selectDetailStaffId = function () {
  return Axios.post('/projectMemberSort/selectDetailStaffId')
}
