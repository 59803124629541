import Axios from '@/axios'
// 转审
export const taskSetAssignee = function (cond = {}) {
  return Axios.post('/actTransfer/taskSetAssignee', cond)
}
// 新增
export const addTaskSetAssignee = function (cond = {}) {
  return Axios.post('/actTransfer/addTaskSetAssignee', cond)
}
// 更改审核接口通过--新
export const disposeTask = function (cond = {}) {
  return Axios.post('/actTransfer/disposeTask', cond)
}
