import Axios from '@/axios'

export const getVatTypeList = function (cond = {}) {
  return Axios.post('/vatType/list', cond)
}

export const addVatType = function (cond = {}) {
  return Axios.post('/vatType/add', cond)
}

export const editVatType = function (cond = {}) {
  return Axios.post('/vatType/edit', cond)
}

export const deleteVatType = function (cond = {}) {
  return Axios.post('/vatType/delete/' + cond)
}

/**********************************************************************/

export const getVatDataList = function (cond = {}) {
  return Axios.post('/vatData/list', cond)
}

export const addVatData = function (cond = {}) {
  return Axios.post('/vatData/add', cond)
}

export const editVatData = function (cond = {}) {
  return Axios.post('/vatData/edit', cond)
}

export const deleteVatData = function (cond = {}) {
  return Axios.post('/vatData/delete/' + cond)
}

/**********************************************************************/

export const getVatRateList = function (cond = {}) {
  return Axios.post('/vatRate/list', cond)
}

export const addVatRate = function (cond = {}) {
  return Axios.post('/vatRate/add', cond)
}

export const editVatRate = function (cond = {}) {
  return Axios.post('/vatRate/edit', cond)
}

export const deleteVatRate = function (cond = {}) {
  return Axios.post('/vatRate/delete/' + cond)
}

/**********************************************************************/

// 增值税弹框说明获取数据
export const getTreeDataList = function (cond = {}) {
  return Axios.post('/vatType/getTreeDataList', cond)
}
