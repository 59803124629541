import Axios from '@/axios'

// 保存/修改用户信息
export const edit = function (cond={}) {
  return Axios.post('/sysRole/edit', cond);
}

// 删除用户
export const del = function (cond) {
  return Axios.post('/sysRole/delete/' + cond);
}

// 保存用户角色关联
export const saveUserRole = function (cond={}) {
  return Axios.post('/sysRole/associationMenuRole', cond);
}

// 获取角色列表
export const listRole = function (cond={}) {
  return Axios.post('/sysRole/listRole', cond);
}
// 保存/修改用户信息
export const add = function (cond={}) {
  return Axios.post('/sysRole/add', cond);
}


