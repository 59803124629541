import Axios from '@/axios'

// //创建项目费用报销
// export const addProjectCost = function (cond={}) {
//   return Axios.post('/projectCost/addProjectCost', cond);
// }

// //报销通过列表
// export const auditPassList = function (cond={}) {
//   return Axios.post('/projectCost/auditPassList', cond);
// }

// //审核
// export const auditProjectCost = function (cond={}) {
//   return Axios.post('/projectCost/auditProjectCost', cond);
// }

// //项目费用申请列表
// export const getApplyForList = function (cond={}) {
//   return Axios.post('/projectCost/getApplyForList', cond);
// }

// //项目费用审核列表
// export const getAuditList = function (cond={}) {
//   return Axios.post('/projectCost/getAuditList', cond);
// }

// //获取项目费用统计列表详细信息
// export const getProjectCostCountDetailed = function (cond={}) {
//   return Axios.post('/projectCost/getProjectCostCountDetailed', cond);
// }

// //获取项目费用统计列表
// export const getProjectCostCountList = function (cond={}) {
//   return Axios.post('/projectCost/getProjectCostCountList', cond);
// }

// //查看详细信息
// export const getProjectCostDetailed = function (cond) {
//   return Axios.post('/projectCost/getProjectCostDetailed/'+ cond);
// }

// //查看详细信息
// export const affirmApply = function (cond) {
//   return Axios.post('/projectCost/affirmApply/'+ cond);
// }
// =======================================================================

// 提交
export const startProcess = function (cond) {
  return Axios.post('/projectExpense/startProcess', cond)
}

// 个人填报列表
export const getApplyForList = function (cond) {
  return Axios.post('/projectExpense/getApplyForList', cond)
}

// 查看详情
export const selectDetailById = function (cond) {
  return Axios.post('/projectExpense/selectDetailById/' + cond)
}

// 暂存编辑
export const undeterminedEdit = function (cond) {
  return Axios.post('/projectExpense/undeterminedEdit', cond)
}

// 暂存
export const tempAdd = function (cond) {
  return Axios.post('/projectExpense/add', cond)
}
// 删除
export const deleteExpense = function (cond) {
  return Axios.post('/projectExpense/delete', cond)
}
// 撤回
export const reject = function (cond) {
  return Axios.post('/projectExpense/reject', cond)
}
// projectCost
// 驳回提交
export const resubmitByReject = function (cond) {
  return Axios.post('/projectExpense/resubmitByReject', cond)
}

// 审批列表
export const searchTask = function (cond) {
  return Axios.post('/projectExpense/searchTask', cond)
}
// 审批不通过
export const approvalReject = function (cond) {
  return Axios.post('/projectExpense/approvalReject', cond)
}
// 审批通过
export const disposeTask = function (cond) {
  return Axios.post('/projectExpense/disposeTask', cond)
}

// 财务确认报销
export const affirmApply = function (cond) {
  return Axios.post('/projectExpense/affirmApply', cond)
}
//项目报销导出（新）
export const exporting = function (cond) {
  return Axios({
    url: '/projectExpense/export/' + cond,
    method: 'get',
    responseType: 'blob',
  })
}
//项目费用明细列表
export const projectExpenseAllList = function (cond) {
  return Axios.post('/projectExpense/projectExpenseAllList', cond)
}

//项目报销的统计值
export const getApplyStatistics = function (cond) {
  return Axios.post('/projectExpense/getApplyStatistics', cond)
}

// 项目报销明细导出
export const projectExpenseListExport = function (cond = {}) {
  return Axios({
    url: '/projectExpense/listExport',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}
