import Axios from '@/axios'

// 提交公共费用报销申请
export const startProcess = function(cond = {}) {
  return Axios.post('/publicSpendingExpenseApply/startProcess', cond)
}

// 暂存公共费用报销申请
export const tempAdd = function(cond = {}) {
  return Axios.post('/publicSpendingExpenseApply/add', cond)
}

// 从暂存状态到提交状态
export const undeterminedEdit = function(cond = {}) {
  return Axios.post('/publicSpendingExpenseApply/undeterminedEdit', cond)
}

// 获取个人公共费用报销申请列表
export const getApplyForList = function(cond = {}) {
  return Axios.post('/publicSpendingExpenseApply/getApplyForList', cond)
}

// 查询报销申请详情
export const selectDetailById = function(cond) {
  return Axios.post('/publicSpendingExpenseApply/selectDetailById/' + cond)
}

// 获取当前用户审批待办列表
export const searchTask = function(cond) {
  return Axios.post('/publicSpendingExpenseApply/searchTask', cond)
}

// 点击审批
export const disposeTask = function(cond) {
  return Axios.post('/publicSpendingExpenseApply/disposeTask', cond)
}
