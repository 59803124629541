const bid = {
  namespaced: true,
  state: {
    ZP_Members: [], //主、陪对应人员数据
  },
  getters: {},
  mutations: {
    SET_ZP_Members: (state, members) => {
      state.ZP_Members = members
    },
  },
  actions: {},
}

export default bid
