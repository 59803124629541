import Axios from '@/axios'

// 添加参数
export const add = function (cond={}) {
  return Axios.post('/sysConfig/add', cond);
}

// 删除参数
export const del = function (cond) {
  return Axios.post('/sysConfig/delete/' + cond);
}

//编辑参数
export const edit = function (cond={}) {
  return Axios.post('/sysConfig/edit', cond);
}

//参数列表
export const listDept = function (cond={}) {
  return Axios.post('/sysConfig/listConfig', cond);
}

//岗位列表
export const listPost = function (cond={}) {
  return Axios.post('/sysPost/listPost', cond);
}

// 删除岗位
export const postDel = function (cond) {
  return Axios.post('/sysPost/delete/' + cond);
}

//编辑岗位
export const postEdit = function (cond={}) {
  return Axios.post('/sysPost/edit', cond);
}

//添加岗位
export const postAdd = function (cond={}) {
  return Axios.post('/sysPost/add', cond);
}

//参数管理
export const listConfig = function (cond={}) {
  return Axios.post('/sysDataConfig/listConfig', cond);
}





