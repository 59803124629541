export default {
  namespaced: true,
  // 数据
  state() {
    return {
      // 记录绩效工时详情组件显示或隐藏
      store_showEditDialog: false,
      // 记录当前详情组件内容
      store_detail: {},
      // 记录当前切换的monthYear
      store_monthYear: '',
      // 记录当前列表是填报列表还是审核列表
      store_pageType: '',
    }
  },
  // 方法
  mutations: {
    // 修改store_showEditDialog状态
    changeShowEditDialog(state, showEditDialog) {
      state.store_showEditDialog = showEditDialog
    },

    // 修改store_detail内容
    changeDetail(state, detail) {
      state.store_detail = detail
    },

    // 修改store_monthYear的值
    changeMonthYear(state, monthYear) {
      state.store_monthYear = monthYear
    },

    // 修改store_pageTye的值
    changePageType(state, pageType) {
      state.store_pageType = pageType
    },
  },

  // 计算属性
  getters: {},
}
