import Axios from '@/axios'
import { Message } from 'element-ui'
import store from '@/store'

//商务支出类型规则列表
export const list = function(cond = {}) {
  return Axios.post('/businessDisbursementTypeRule/list', cond)
}

//商务支出类型规则保存
export const edit = function(cond = {}) {
    return Axios.post('/businessDisbursementTypeRule/edit', cond)
  }
  
  