const project = {
  namespaced: true,
  state: {
    projectInformation: { projectDeptList: [] }, //项目基本信息
    addAgreementFileList: [], //新增项目附件
    delAgreementFileList: [], //商务移除项目附件
    projectFileList: [], //项目附件
    projectDeptMemberList: [], //项目成员
    memberData: {}, //项目成员
    projectDeptBelongList: [], //项目部门 只有该用户有权限的
    projectDisposableExpenses: {}, //项目可支配费用
    originalForm: {}, //项目信息原始值

    stageData: {}, //项目阶段
    projectStageBelongList: [], //项目阶段类型
    projectStagePlanCollect: [], //项目阶段
    projectStageTaskList: [], //任务
    agreementStageResponseList: [], //合同阶段
    projectCostPlanFileList: [], //成本预估
    projectCostPlanList: [], //成本预估

    ProjectDisRuleOptions: {}, //项目绩效
  },
  getters: {},
  mutations: {
    SET_PROJECTDISRULEOPTIONS: (state, payload) => {
      state.ProjectDisRuleOptions = payload.ProjectDisRuleOptions || {}
    },
    SET_PROJECTCOSTPLANLIST: (state, payload) => {
      state.projectCostPlanList = payload.projectCostPlanList || []
    },

    SET_PROJECTCOSTPLANFILELIST: (state, payload) => {
      state.projectCostPlanFileList = payload.projectCostPlanFileList || []
    },

    SET_STAGEDATA: (state, payload) => {
      state.stageData = payload.stageData || {}
    },

    SET_PROJECTSTAGEBELONGLIST: (state, payload) => {
      state.projectStageBelongList = payload.projectStageBelongList || []
    },

    SET_PROJECTSTAGEPLANCOLLECT: (state, payload) => {
      state.projectStagePlanCollect = payload.projectStagePlanCollect || []
    },

    SET_PROJECTSTAGETASKLIST: (state, payload) => {
      state.projectStageTaskList = payload.projectStageTaskList || []
    },

    SET_AGREEMENTSTAGERESPONSELIST: (state, payload) => {
      state.agreementStageResponseList = payload.agreementStageResponseList || []
    },

    SET_ORIGINALFORM: (state, payload) => {
      state.originalForm = payload.originalForm || {}
    },

    SET_PROJECTINFORMATION: (state, payload) => {
      state.projectInformation = payload.projectInformation || {}
    },

    SET_PROJECTFILELIST: (state, payload) => {
      state.projectFileList = payload.projectFileList || []
    },

    ADD_PROJECTFILELIST: (state, payload) => {
      state.addAgreementFileList = payload.addAgreementFileList || []
    },
    DEL_PROJECTFILELIST: (state, payload) => {
      state.delAgreementFileList = payload.delAgreementFileList || []
    },
    SET_PROJECTDEPTMEMBERLIST: (state, payload) => {
      state.projectDeptMemberList = payload.projectDeptMemberList || []
    },

    SET_MEMBERDATA: (state, payload) => {
      state.memberData = payload.memberData || {}
    },

    SET_PROJECTDEPTBELONGLIST: (state, payload) => {
      state.projectDeptBelongList = payload.projectDeptBelongList || []
    },

    SET_PROJECTSTAGEPLANCOLLECT: (state, payload) => {
      state.projectStagePlanCollect = payload.projectStagePlanCollect || {}
    },

    SET_PROJECTDISPOSABLEEXPENSES: (state, payload) => {
      state.projectDisposableExpenses = payload.projectDisposableExpenses || {}
    },
  },
  actions: {},
}

export default project
