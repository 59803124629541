import Axios from '@/axios'
// 提交
export const startProcess = function (cond = {}) {
  return Axios.post('/expenditureAdvance/startProcess', cond)
}

// 个人填报列表
export const getApplyForList = function (cond = {}) {
  return Axios.post('/expenditureAdvance/getApplyForList', cond)
}

// 查看详情
export const selectDetailById = function (cond) {
  return Axios.post('/expenditureAdvance/selectDetailById/' + cond)
}

// 暂存编辑
export const undeterminedEdit = function (cond = {}) {
  return Axios.post('/expenditureAdvance/undeterminedEdit', cond)
}

// 暂存
export const tempAdd = function (cond = {}) {
  return Axios.post('/expenditureAdvance/add', cond)
}
// 删除
export const deleteExpense = function (cond = {}) {
  return Axios.post('/expenditureAdvance/delete', cond)
}
// 撤回
export const reject = function (cond = {}) {
  return Axios.post('/expenditureAdvance/reject', cond)
}
// 驳回提交
export const resubmitByReject = function (cond = {}) {
  return Axios.post('/expenditureAdvance/resubmitByReject', cond)
}

// 审批列表
export const searchTask = function (cond = {}) {
  return Axios.post('/expenditureAdvance/searchTask', cond)
}
// 审批不通过
export const approvalReject = function (cond = {}) {
  return Axios.post('/expenditureAdvance/approvalReject', cond)
}
// 审批通过
export const disposeTask = function (cond = {}) {
  return Axios.post('/expenditureAdvance/disposeTask', cond)
}

// 财务确认报销
export const affirmApply = function (cond = {}) {
  return Axios.post('/expenditureAdvance/affirmApply', cond)
}
//导出
export const exporting = function (cond) {
  return Axios({
    url: '/expenditureAdvance/export/' + cond,
    method: 'get',
    responseType: 'blob',
  })
}
//明细列表
export const publicSpendingExpenseAllList = function (cond = {}) {
  return Axios.post('/expenditureAdvance/allList', cond)
}
//统计值
export const getApplyStatistics = function (cond = {}) {
  return Axios.post('/expenditureAdvance/getApplyStatistics', cond)
}
//个人额度
export const onlyGroupQuota = function (cond = {}) {
  return Axios.post('/expenditureAdvance/onlyGroupQuota', cond)
}
//冲销统计
export const totalCounteract = function (cond = {}) {
  return Axios.post('/counteractExpense/totalCounteract', cond)
}
//归还经费
export const returnExpenditure = function (cond = {}) {
  return Axios.post('/expenditureAdvance/returnExpenditure', cond)
}
