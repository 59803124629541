import * as login from './login/index'
import * as menu from './menu/index'
import * as user from './user/index'
import * as role from './role/index'
import * as dict from './dict/index'
import * as sysDept from './sysDept/index'
import * as sysConfig from './sysConfig/index'
import * as dictClassify from './dictClassify/index'
import * as hour from './hour/index'
import * as common from './common/index'
import * as certificationManage from './certificationManage/index'
import * as project from './project/index'
import * as businessManage from './businessManage/index'
import * as agreement from './agreement/index'
import * as projectCost from './projectCost/index'
import * as projectCostPlan from './projectCostPlan/index'
import * as businessPerformance from './businessPerformance/index'
import * as outsource from './outsource/index'
import * as partnerCompany from './partnerCompany/index'
import * as funds from './funds/index'
import * as payment from './payment/index'
import * as invoice from './invoice/index'
import * as performanceRules from './performanceRules/index'
import * as performanceManagement from './performanceManagement/index'
import * as productionControl from './productionControl/index'
import * as businessExpense from './businessExpense/index'
import * as assetsControl from './assetsControl/index'
import * as limitConfiguration from './limitConfiguration/index'
import * as publicSpendingExpense from './publicSpendingExpenseApply/index'
import * as publicSpending from './publicSpendingExpense/index'
import * as publicPayment from './publicPayment/index'
import * as backlog from './backlog/index'
import * as register from './register/index'
import * as productionCostRatioLog from './productionCostRatioLog/index'
import * as accunting from './accunting/index'
import * as businessTally from './businessTally/index'
import * as disburementRatio from './disburementRatio/index'
import * as vat from './vat/index'
import * as projectPerformance from './projectPerformance/index'
import * as fundsConfiguration from './funds-configuration/index'
import * as fundsGiveBack from './funds-give-back/index'
import * as projectMemberSort from './projectMemberSort/index'
import * as log from './log/index'
import * as memberApplication from './memberApplication/index'
import * as addAuditor from './add-auditor/index'
import * as businessOutsourceCost from './business-outsource-cost/index'
import * as bid from './bid/index'

const api = {
  common, //公用
  login, //登录
  menu, //菜单
  user, //用户
  role, //角色
  dict, //字典
  dictClassify, //字典类型
  sysDept, //部门
  businessManage, //商务
  sysConfig,
  project,
  hour,
  certificationManage, //资质
  agreement,
  projectCost, //项目报销
  projectCostPlan,
  businessPerformance, //商务绩效
  outsource, //外协
  partnerCompany, //合作方公司
  funds, // 经费申请
  payment, // 付款申请
  invoice, // 发票申请
  performanceRules, //绩效规则
  performanceManagement, //绩效管理
  productionControl, //生产绩效管理
  businessExpense, //商务报销
  assetsControl, //资产管理
  limitConfiguration, //限额配置
  publicSpendingExpense, //!公共费用报销申请
  publicSpending, //!公共费用报销
  publicPayment, //公共费用付款申请
  backlog, //待办列表
  register, //支出登记
  productionCostRatioLog,
  accunting, //商务记账规则
  businessTally,
  disburementRatio, //商务支出类型规则
  vat, //增值税配置规则
  projectPerformance, //项目绩效分配
  fundsConfiguration, //经费额度配置
  fundsGiveBack, //经费预支自主归还
  projectMemberSort, //项目列表排序
  log, //操作记录
  memberApplication, //历史收款单位
  addAuditor, //报销、付款申请、支出登记、预支-增加转审人-通用通过审批接口
  businessOutsourceCost, //外协费用
  bid, //投标管理
}

export default api
