import Axios from "@/axios";

// 公司公共费明细列表
export const companyPublicCostDetailController = function(code = {}) {
  return Axios.post("/companyPublicCostDetailController/list", code);
};

// 查看公司总公共费
export const companyPublicCostListTotal = function(code = {}) {
  return Axios.post("/companyPublicCostDetailController/listTotal", code);
};

// 查看部门总绩效列表
export const departmentPerformanceListTotal = function(code = {}) {
  return Axios.post("/departmentPerformanceDetailController/listTotal", code);
};

// 部门绩效明细列表
export const departmentPerformanceDetailController = function(code = {}) {
  return Axios.post("/departmentPerformanceDetailController/list", code);
};

// 生产绩效列表
export const productPerformanceController = function(code = {}) {
  return Axios.post("/productPerformanceController/list", code);
};

// 利润商务绩效列表
export const profitBusinessPerformanceController = function(code = {}) {
  return Axios.post("/profitBusinessPerformanceController/list", code);
};

// 项目利润列表
export const projectProfitController = function(code = {}) {
  return Axios.post("/projectProfitController/list", code);
};
