import Vue from 'vue'

Vue.filter('dateFormat', function (value, fmt) {
  if (value) {
    if (fmt) {
      if (typeof value == 'string') {
        value = value.replace(/-/g, '/')
      }
      return new Date(value).format(fmt)
    } else {
      return new Date(value).format('yyyy-MM-dd')
    }
  } else {
    return ''
  }
})

Vue.filter('timeFormat', function (value, fmt) {
  if (value) {
    if (fmt) {
      if (typeof value == 'string') {
        value = value.replace(/-/g, '/')
      }

      return new Date(value).format(fmt)
    } else {
      return new Date(value).format('yyyy-MM-dd hh:mm:ss')
    }
  } else {
    return ''
  }
})

Vue.filter('completionDate', function (v) {
  if (v) {
    var a = new Date(`${new Date().format('yyyy-MM-dd')} 00:00:00`).getTime()
    var b = new Date(v.replace(/-/g, '/')).getTime()

    if (a == b) {
      return 'color:rgb(230, 162, 60);'
    } else if (b < a) {
      return 'color:rgb(245, 108, 108);'
    } else {
      return 'color:#409EFF'
    }
  } else {
    return ''
  }
})

Vue.filter('portrait', function (value) {
  return value ? value : require('@/assets/images/portrait.png')
})

Vue.filter('dict', function (value, data, key = 'dictVal', label = 'dictName') {
  // if (value && value != null && value != undefined ) {//以前的
  if (
    (value && value != null && value != undefined && data && data.length) ||
    (value == 0 && data && data.length)
  ) {
    //value等于0为了商务绩效中状态为0
    for (let i = 0; i < data.length; i++) {
      if (data[i][key] == value) {
        value = data[i][label]
      }
    }
    return value
  } else {
    return ''
  }
})

Vue.filter(
  'cascader',
  function (value, data, key = 'dictVal', label = 'dictName', pid = 'parentId', separator = '/') {
    if (value && data.length) {
      //避免异步数据没返回时报错
      var arr = []

      function getParent(item) {
        for (let i = 0; i < data.length; i++) {
          let n = data[i]
          if (n.id == item[pid]) {
            arr.unshift(n[label])
            if (n[pid] != 0) {
              getParent(n)
            }
          }
        }
      }

      for (let i = 0; i < data.length; i++) {
        let item = data[i]

        if (item[key] == value) {
          arr.push(item[label])
          if (item[pid] != 0) {
            getParent(item)
          }
        }
      }
      return arr.join(separator)
    } else {
      return ''
    }
  }
)

// 时分秒
Vue.filter('completeTime', function (value) {
  if (value) {
    //将时间戳格式转换成年月日时分秒
    var date = new Date(value)
    var Y = date.getFullYear() + '-'
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
    var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '

    var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
    var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    var strDate = Y + M + D + h + m + s
    return strDate
  } else {
    return ''
  }
})

// 金额转中文大写
Vue.filter('numberParseChina', function (value) {
  if (value) {
    let isNegative = false
    if (value < 0) {
      isNegative = true
      value = Math.abs(value)
    }

    let cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖')
    let cnIntRadice = new Array('', '拾', '佰', '仟')
    let cnIntUnits = new Array('', '万', '亿', '兆')
    let cnDecUnits = new Array('角', '分', '毫', '厘')
    let cnInteger = '整'
    let cnIntLast = '圆'
    let maxNum = 999999999999999.9999
    let integerNum
    let decimalNum
    let chineseStr = ''
    let parts

    if (value == '') {
      return ''
    }

    value = parseFloat(value)

    if (value >= maxNum) {
      return ''
    }

    if (value == 0) {
      chineseStr = cnNums[0] + cnIntLast + cnInteger
      return chineseStr
    }

    value = value.toString()

    if (value.indexOf('.') == -1) {
      integerNum = value
      decimalNum = ''
    } else {
      parts = value.split('.')
      integerNum = parts[0]
      decimalNum = parts[1].substr(0, 4)
    }

    if (parseInt(integerNum, 10) > 0) {
      let zeroCount = 0
      let IntLen = integerNum.length

      for (let i = 0; i < IntLen; i++) {
        let n = integerNum.substr(i, 1)
        let p = IntLen - i - 1
        let q = p / 4
        let m = p % 4

        if (n == '0') {
          zeroCount++
        } else {
          if (zeroCount > 0) {
            chineseStr += cnNums[0]
          }

          zeroCount = 0
          chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
        }

        if (m == 0 && zeroCount < 4) {
          chineseStr += cnIntUnits[q]
        }
      }

      chineseStr += cnIntLast
    }

    if (decimalNum != '') {
      let decLen = decimalNum.length

      for (let i = 0; i < decLen; i++) {
        let n = decimalNum.substr(i, 1)

        if (n != '0') {
          chineseStr += cnNums[Number(n)] + cnDecUnits[i]
        }
      }
    }

    if (chineseStr == '') {
      chineseStr += cnNums[0] + cnIntLast + cnInteger
    } else if (decimalNum == '') {
      chineseStr += cnInteger
    }

    if (isNegative) {
      chineseStr = '负' + chineseStr
    }

    return chineseStr
  } else {
    return '零'
  }
})
