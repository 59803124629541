//身份证
export const idCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/

//手机号码
export const mobile = /^[1][3,4,5,7,8,9][0-9]{9}$/

//简单电话号码的验证(手机，固定电话)
export const phone = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/

//正整数（包括0）
export const integer = /^([1-9]\d*|[0]{1,1})$/

//邮箱
export const email = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/

//字母和数字
export const cardNumber = /^[0-9a-zA-Z]*$/

//正数
export const positiveNumber = /^\d+(\.\d+)?$/

//最多保留2位小数点包含0
export const positiveInteger =
  /^(([0-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})|([0]\.\d{1,2}|[0-9][0-9]*\.)))$/

//最多保留2位小数点不包含
export const positiveInteger2 = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/

//电话号码检验2
export const phone2 =
  /^(((\d{3,4}-)?[0-9]{7,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/

// 待办/路由标识符
export const backlogList = [
  {
    actkey: 'BUSINESS_EXPENSE',
    name: '商务费用报销单',
    examineCode: 'SWBXSHLB',
    passCosCode: 'SWBXFFLB',
    examinePath: '',
    passCostPath: '',
    sumQuantity: 0,
  },
  {
    actkey: 'PROJECT_EXPENSE',
    name: '项目费用报销单',
    examineCode: 'XMBXSHLB',
    passCosCode: 'XMBXFFLB',
    examinePath: '',
    passCostPath: '',
    sumQuantity: 0,
  },
  {
    actkey: 'PUBLIC_SPENDING_EXPENSE',
    name: '公共费用报销单',
    examineCode: 'GGFYBXSHLB',
    passCosCode: 'GGFYBXFFLB',
    examinePath: '',
    passCostPath: '',
    sumQuantity: 0,
  },
  {
    actkey: 'PROJECT_PAYMENT_APPLICATION',
    name: '项目付款申请单',
    examineCode: 'XMFKSQSHLB',
    passCosCode: 'XMFKSQFFLB',
    examinePath: '',
    passCostPath: '',
    sumQuantity: 0,
  },
  {
    actkey: 'PUBLIC_SPENDING_PAYMENT_APPLICATION',
    name: '公共费用付款申请单',
    examineCode: 'GGFYFKSQSHLB',
    passCosCode: 'GGFYFKSQFFLB',
    examinePath: '',
    passCostPath: '',
    sumQuantity: 0,
  },
  //支出
  {
    actkey: 'BUSINESS_REGISTRATION_EXPENDITURE',
    name: '商务支出登记单',
    examineCode: 'SWFYDJGLSHLB',
    examinePath: '',
    sumQuantity: 0,
  },
  {
    actkey: 'EXTERNAL_SPENDING_REGISTRATION_EXPENDITURE',
    name: '公共支出登记单',
    examineCode: 'GGFYDJGLSHLB',
    examinePath: '',
    sumQuantity: 0,
  },
  {
    actkey: 'PROJECT_REGISTRATION_EXPENDITURE',
    name: '项目支出登记单',
    examineCode: 'XMFYDJGLSHLB',
    examinePath: '',
    sumQuantity: 0,
  },
  //支出确认
  {
    actkey: 'INVENTORY_KEEPER_REGISTRATION_EXPENDITURE',
    name: '库管支出登记单',
    examineCode: 'ZCDJKGDJGLQRLB',
    examinePath: '',
    sumQuantity: 0,
  },
  {
    actkey: 'KITCHEN_REGISTRATION_EXPENDITURE',
    name: '厨房支出登记单',
    examineCode: 'ZCDJCSDJGLQRLB',
    examinePath: '',
    sumQuantity: 0,
  },
  // 预支经费
  {
    actkey: 'EXPENDITURE_ADVANCE',
    name: '预支经费申请单',
    examineCode: 'JFYZSHLB',
    passCosCode: 'JFYZFFLB',
    examinePath: '',
    passCostPath: '',
    sumQuantity: 0,
  },
  {
    actkey: 'EXPENDITURE_ADVANCE_RETURN',
    name: '预支经费归还单',
    examineCode: 'JFYZZZGHSHLB',
    examinePath: '',
    sumQuantity: 0,
  },
  // 发票开票
  {
    actkey: 'INVOICE_APPLICATION',
    name: '合同发票申请单',
    examineCode: 'XMFPGLSHLB',
    passCosCode: 'XMFPGLKPLB',
    examinePath: '',
    sumQuantity: 0,
  },
  // 外协费用申请单
  {
    actkey: 'BUSINESS_PAYMENT_APPLICATION',
    name: '外协费用申请单',
    examineCode: 'SWWXFYSHLB',
    passCosCode: 'SWWXFYFFLB',
    examinePath: '',
    sumQuantity: 0,
  },
  // 商务绩效分配方案
  {
    actkey: 'BUSINESS_PERFORMANCE_SCHEME',
    name: '商务绩效分配方案',
    examineCode: 'JXSH',
    examinePath: '',
    sumQuantity: 0,
  },
  // 项目绩效分配方案
  {
    actkey: 'PROJECT_PERFORMANCE_SCHEME',
    name: '项目绩效分配方案',
    examineCode: 'XMJXFAGLSHLB',
    examinePath: '',
    sumQuantity: 0,
  },
]
