import Axios from '@/axios'
// 操作列表
export const list = function (cond = {}) {
  return Axios.post('/sysLog/list', cond)
}
// 全部列表
export const allList = function (cond = {}) {
  return Axios.post('/sysLog/allList', cond)
}
// 操作详情
export const selectDetailById = function (id) {
  return Axios.post('/sysLog/selectDetailById/' + id)
}
