import Axios from '@/axios'

//获取资产列表列表
export const getList = function (cond={}) {
  return Axios.post('/propertyManageContrller/getList', cond);
}

//删除资产
export const deletePropertyManage = function (cond={}) {
  return Axios.post('/propertyManageContrller/deletePropertyManage/'+cond);
}


//新增资产
export const addPropertyManage = function (cond={}) {
  return Axios.post('/propertyManageContrller/addPropertyManage', cond);
}

//修改资产
export const updatePropertyManage = function (cond={}) {
  return Axios.post('/propertyManageContrller/updatePropertyManage', cond);
}

//
//excel批量新增资产管理 
export const addBatchPropertyManage = function(cond = {}) {
  return Axios.post("/propertyManageContrller/addBatchPropertyManage", cond);
};