import ElementUI from 'element-ui';

ElementUI.TableColumn.props.showOverflowTooltip = {
    type: Boolean,
    default: true
};

ElementUI.Pagination.props.layout.default = "total, prev, pager, next , jumper,sizes";
ElementUI.Pagination.props.pageSizes.default = ()=>{return [50,100,200,500]};

ElementUI.InputNumber.props.controlsPosition.default = 'right'
