
const dict = {
  namespaced: true,
  state: {
    data:{},
  },
  mutations: {
    SET_DATA: (state, payload) => {
      var data = state.data.deepClone();
      data[payload.code] = payload.data;
      state.data = data;
    },
  },
  actions: {
    setData({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_DATA',{data});
        resolve();
      });
    },
  }
}

export default dict
