import Axios from '@/axios'

// 获取登录验证的信息
export const selectList = function (cond = {}) {
  return Axios.post('/memberApplication/selectList', cond)
}

//删除历史收款单位
export const deleteMemberApplication = function (cond) {
  return Axios.post('/memberApplication/delete/' + cond)
}
