import Axios from '@/axios'


export const add = function (cond={}) {
  return Axios.post('/sysMenu/add', cond);
}


export const menusList = function (cond={}) {
  return Axios.post('/sysMenu/listMenu', cond);
}

export const edit = function (cond={}) {
  return Axios.post('/sysMenu/edit', cond);
}

export const queryMenu = function () {
  return Axios.get('/sysMenu/queryMenu');
}

export const del = function (id) {
  return Axios.post('/sysMenu/delete/' + id);
}

export const queryMenuByRoleId = function (id) {
  return Axios.post('/sysMenu/query/' + id);
}

export const read = function (code) {
  return Axios.post('/sysMenuBacklog/read/' + code);
}



