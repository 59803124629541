import Axios from '@/axios'
import { Message } from "element-ui";


export const add = function (cond={}) {
  return Axios.post('/sysDictType/add', cond);
};

export const edit = function (cond={}) {
  return Axios.post('/sysDictType/edit', cond);
};

export const listSysDictType = function (cond={}) {

  return Axios.post('/sysDictType/listSysDictType',cond);
};

export const del = function (condition) {
  return Axios.post('/sysDictType/delete/' + condition);
};
