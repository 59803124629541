import Axios from '@/axios'
import { Message } from 'element-ui'
import store from '@/store'

//经费额度配置列表
export const list = function (cond = {}) {
  return Axios.post('/expenditureAdvanceLimitRule/list', cond)
}
//经费额度配置保存
export const edit = function (cond = {}) {
  return Axios.post('/expenditureAdvanceLimitRule/edit', cond)
}
