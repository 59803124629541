import Axios from '@/axios'

// 全部列表
export const productPerformanceAllocationList = function (code = {}) {
  return Axios.post('/productPerformanceAllocation/list', code)
}

// 填报列表
export const listByClassifyManagerList = function (code = {}) {
  return Axios.post('/productPerformanceAllocation/listByClassifyManager', code)
}

// 项目经理选择项目列表
export const classifyManagerProjectList = function (code = {}) {
  return Axios.post('/productPerformanceAllocation/classifyManagerProjectList', code)
}

// 创建并提交信息
export const addOrSubmit = function (code = {}) {
  return Axios.post('/productPerformanceAllocation/addOrSubmit', code)
}

// 添加暂存信息
export const productPerformanceAllocationAdd = function (code = {}) {
  return Axios.post('/productPerformanceAllocation/add', code)
}

// 暂存状态编辑信息
export const undeterminedEdit = function (code = {}) {
  return Axios.post('/productPerformanceAllocation/undeterminedEdit', code)
}

// 暂存状态编辑并提交信息
export const undeterminedEditOrSubmit = function (code = {}) {
  return Axios.post('/productPerformanceAllocation/undeterminedEditOrSubmit', code)
}

// 审批列表
export const listByApproval = function (code = {}) {
  return Axios.post('/productPerformanceAllocation/listByApproval', code)
}

// 生产绩效明细
export const selectDetailById = function (id) {
  return Axios.post('/productPerformanceAllocation/selectDetailById/' + id)
}

// 撤回
export const productReject = function (code = {}) {
  return Axios.post('/productPerformanceAllocation/reject', code)
}

// 删除
export const productDelete = function (code = {}) {
  return Axios.post('/productPerformanceAllocation/delete', code)
}

// 主管经理审批通过
export const competentManagerPass = function (code = {}) {
  return Axios.post('/productPerformanceAllocation/competentManagerPass', code)
}

// 主管经理审批不通过
export const competentManagerReject = function (code = {}) {
  return Axios.post('/productPerformanceAllocation/competentManagerReject', code)
}

// 分管高管审批审批通过
export const fzManagerPass = function (code = {}) {
  return Axios.post('/productPerformanceAllocation/fzManagerPass', code)
}

// 分管高管审批不通过
export const fzManagerReject = function (code = {}) {
  return Axios.post('/productPerformanceAllocation/fzManagerReject', code)
}

// 意见列表
export const opinionRecordList = function (code = {}) {
  return Axios.post('/opinionRecord/list', code)
}

// 生产绩效确认列表
export const payedList = function (code = {}) {
  return Axios.post('/productPerformanceAllocation/payedList', code)
}

// 绩效全部发放
export const affirmApply = function (code = {}) {
  return Axios.post('/productPerformanceAllocation/affirmApply', code)
}

// 绩效部分发放
export const affirmPortionApply = function (code = {}) {
  return Axios.post('/productPerformanceAllocation/affirmPortionApply', code)
}

// 意见列表
export const opinionRecordNewList = function (code = {}) {
  return Axios.post('/opinionRecord/actList', code)
}
