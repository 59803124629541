import Vue from 'vue'
import VueRouter from 'vue-router'
import Axios from '@/axios'
import store from '@/store'
import { initTreeData } from '@/util/common'
import { Message } from 'element-ui'

import api from '@/api/index'
import { queryMenu } from '@/api/menu'

import warterMark from '@/util/waterMark'
import { backlogList } from '@/util/regular'
import { getBacklogFn } from '@/util/jsencrypt'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  // mode: 'history',
  //base: process.env.BASE_URL,
  routes: [
    {
      path: '/login', // 登录页面
      name: 'Login',
      meta: { title: '登录', verificationLogin: true },
      component: () => import('@/views/Login.vue'),
    },
  ],
})

router.beforeEach((to, from, next) => {
  // 跳转的页面是登录页
  if (to.meta.verificationLogin) {
    warterMark.set('')
    next()
  } else {
    if (
      process.env.NODE_ENV == 'development' ||
      window.location.port == '8817' ||
      window.location.port == '8815'
    ) {
    } else {
      warterMark.set(store.state.user.userInfo.userName)
    }

    if (store.state.user.userInfo.token) {
      if (store.state.user.loadMenus) {
        // 判断是否已拉取过菜单防止死循环
        next()
      } else {
        loadMenus(next, to)
      }
    } else {
      next(`/login?redirect=${to.path}`)
    }
  }
})

const loadMenus = (next, to) => {
  api.menu
    .queryMenu()
    .then(async res => {
      // 赋值红点提示
      const { menus } = await getBacklogFn(res.data)
      var data = menus
      // var data = res.data
      var codes = []

      if (data && data.length > 0) {
        var navMenu = initTreeData(data)

        navMenu.map((item, index) => {
          if (item.code == 'INDEX') {
            navMenu.unshift(navMenu.splice(index, 1)[0])
          }
        })
        store.commit({
          type: 'user/SET_MENUS',
          menus: navMenu,
        })

        var dataArr = []

        function getChildren(id) {
          var arr = []
          for (let i = 0; i < data.length; i++) {
            if (data[i].parentId == id) {
              let obj = {
                path: data[i].href || data[i].code,
                name: data[i].code,
                meta: {
                  title: data[i].menuName,
                },
                component: loadView(data[i].component),
              }
              if (data[i].component) {
                obj.component = loadView(data[i].component)
              }

              if (getChildren(data[i].id)) {
                obj.children = getChildren(data[i].id)
              }
              arr.push(obj)
            }
          }
          if (arr.length > 0) {
            return arr
          } else {
            return null
          }
        }

        for (let i = 0; i < data.length; i++) {
          if (data[i].parentId == 0) {
            let obj = {
              path: data[i].href || data[i].code,
              name: data[i].code,
              meta: {
                title: data[i].menuName,
              },
              component: loadView(data[i].component),
            }
            if (getChildren(data[i].id)) {
              obj.children = getChildren(data[i].id)
            }

            dataArr.push(obj)
          }
        }

        var href = dataArr[0].linkTo ? dataArr[0].linkTo : dataArr[0].path

        function recursion(data) {
          if (data.children && data.children.length > 0) {
            recursion(data.children[0])
          } else {
            href = data.linkTo ? data.linkTo : data.path

            dataArr.push({
              path: '*',
              redirect: href,
              hidden: true,
            })

            store
              .dispatch('user/setLoadMenus', true)
              .then(() => {
                router.addRoutes(dataArr)

                next({
                  ...to,
                  replace: true,
                }) // hack方法确保addRoutes已完成
              })
              .catch(() => {})
          }
        }
        let Obj = {}
        dataArr.forEach(e => {
          if (e.name == 'INDEX') {
            Obj = e
          }
        })
        recursion(Obj)
      } else {
        Message.error('该用户没有分配权限！')
      }
    })
    .catch(err => {
      console.log(err)
    })
}

const loadView = view => {
  if (view) {
    return () => import(`@/${view}.vue`)
  } else {
    return {
      render: c => c('router-view'),
    }
  }
}

export default router
