import Axios from '@/axios'

// 提交公共费用报销
export const startProcess = function (cond = {}) {
  return Axios.post('/publicSpendingExpense/startProcess', cond)
}

// 暂存公共费用报销申请
export const tempAdd = function (cond = {}) {
  return Axios.post('/publicSpendingExpense/add', cond)
}

// 从暂存编辑状态
export const undeterminedEdit = function (cond = {}) {
  return Axios.post('/publicSpendingExpense/undeterminedEdit', cond)
}

// 获取个人公共费用报销列表
export const getApplyForList = function (cond = {}) {
  return Axios.post('/publicSpendingExpense/getApplyForList', cond)
}

// 查询报销详情
export const selectDetailById = function (cond) {
  return Axios.post('/publicSpendingExpense/selectDetailById/' + cond)
}

// 获取当前用户审批待办列表
export const searchTask = function (cond) {
  return Axios.post('/publicSpendingExpense/searchTask', cond)
}

// 点击审批通过
export const disposeTask = function (cond) {
  return Axios.post('/publicSpendingExpense/disposeTask', cond)
}

// 点击审批不通过
export const approvalReject = function (cond) {
  return Axios.post('/publicSpendingExpense/approvalReject', cond)
}

// 删除暂存
export const deleteExpense = function (cond) {
  return Axios.post('/publicSpendingExpense/delete', cond)
}

// 撤回
export const rejectExpense = function (cond) {
  return Axios.post('/publicSpendingExpense/reject', cond)
}

// 财务确认报销
export const affirmApply = function (cond) {
  return Axios.post('/publicSpendingExpense/affirmApply', cond)
}

// 从驳回状态提交
export const resubmitByReject = function (cond = {}) {
  return Axios.post('/publicSpendingExpense/resubmitByReject', cond)
}

//公共费用导出
export const exporting = function (cond) {
  return Axios({
    url: '/publicSpendingExpense/export/' + cond,
    method: 'get',
    responseType: 'blob',
  })
}

//公共费用明细列表
export const publicSpendingExpenseAllList = function (cond) {
  return Axios.post('/publicSpendingExpense/publicSpendingExpenseAllList', cond)
}

//公共费用报销的统计值
export const getApplyStatistics = function (cond) {
  return Axios.post('/publicSpendingExpense/getApplyStatistics', cond)
}
// 公共费用报销明细导出
export const publicSpendingExpenseListExport = function (cond = {}) {
  return Axios({
    url: '/publicSpendingExpense/listExport',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}
