<template>
  <div id="app" :class="{ isCollapse: adaptation.isCollapse }">
    <template v-if="isRouterAlive">
      <template v-if="verificationLogin">
        <router-view></router-view>
      </template>
      <template v-else>
        <div class="menu transition">
          <div class="logo">
            <img :src="logo" class="transition" />
            <div class="n">公司管理平台</div>
          </div>
          <el-scrollbar class="menu_content">
            <VerMenuEle :isCollapse.sync="adaptation.isCollapse"></VerMenuEle>
          </el-scrollbar>
        </div>
        <!-- 悬浮拖动tag -->
        <DraggableTag v-show="adaptation.isCollapse" @changeIsCollapse="onTagClick"></DraggableTag>
        <Header class="header transition" :isCollapse.sync="adaptation.isCollapse" />
        <router-view class="main transition"></router-view>
      </template>
    </template>
  </div>
</template>

<script>
import { throttle } from '@/util/common'
export default {
  name: 'App',
  components: {
    Header: () => import('@/views/common/Header.vue'),
    VerMenuEle: () => import('@/views/common/VerMenuEle.vue'),
    DraggableTag: () => import('@/components/DraggableTag.vue'),
  },
  data() {
    return {
      logo: require('@/assets/images/logo2.png'),
      verificationLogin: true,
      isRouterAlive: true,
      adaptation: {
        isCollapse: false,
        width: window.innerWidth,
        height: window.innerHeight,
      },
    }
  },
  provide() {
    return {
      reload: this.reload,
      adaptation: this.adaptation,
    }
  },
  watch: {
    $route: {
      //immediate:true,//初始化立即执行
      deep: true, //对象深度监测
      handler: function (to) {
        this.verificationLogin = to.meta.verificationLogin
      },
    },
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    onTagClick(value) {
      this.adaptation.isCollapse = value
    },
  },
  mounted() {
    window.addEventListener('resize', e => {
      this.adaptation.width = e.target.innerWidth
      this.adaptation.height = e.target.innerHeight
    })
  },
}
</script>

<style lang="scss">
@import '@/styles/config.scss';
#app {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  background: #f6f9fd;
  //@media only screen and (min-aspect-ratio: 900/460) {
  //    @media screen and (max-height: 460px) {
  //        height: 460px;
  //    }
  //}
  //@media only screen and (max-aspect-ratio: 900/460) {
  //    @media screen and (max-width: 900px) {
  //        width: 900px;
  //    }
  //}
  .transition {
    transition: 0.1s cubic-bezier(0.92, 1.1, 0.71, 0.94);
  }
  &.isCollapse {
    > .header {
      left: 0px;
    }
    > .menu {
      width: 0px;
    }
    > .main {
      left: 0px;
    }
  }
  > .header {
    position: absolute;
    top: 0;
    left: 280px;
    right: 0;
    height: 70px;
    border-bottom: 1px solid #ddd;
    background: #fff;
    z-index: 999;
  }
  > .menu {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 280px;
    background: rgba(1, 77, 103, 1);
    overflow: hidden;
    .logo {
      text-align: center;
      padding: 30px 0;
      height: 174px;
      img {
        border-radius: 50%;
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
        background: rgba(255, 255, 255, 0.8);
      }
      .n {
        color: #fff;
        line-height: 24px;
      }
      + .menu_content {
        padding-top: 0;
      }
    }
    .menu_content {
      height: calc(100% - 174px); //减去logo图高度
      // height:100%;
      padding-top: 20px;
    }
  }
  > .main {
    position: absolute;
    top: 71px;
    left: 280px;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
}
.el-menu--vertical {
  .el-menu--popup {
    background: rgba(1, 77, 103, 1) !important;
    .iconfont {
      font-size: 16px;
      margin-right: 5px;
      color: #fff;
    }
    i {
      color: #f2f3f5;
    }
    .el-menu-item {
      &.is-active {
        color: #fff;
        background: #001e28 !important;
      }
    }
  }
}
</style>
