import Axios from '@/axios'
import { Message } from 'element-ui'

//工时审批
export const checkManhours = function (cond = {}) {
  return Axios.post('/manhour/checkManhours', cond)
}

//员工工时按项目统计
export const getAllMahhoursByProject = function (cond = {}) {
  return Axios.post('/manhour/getAllMahhoursByProject', cond)
}

//获取当前用户的所有工时信息
export const getAllMahhoursByUser = function (cond = {}) {
  return Axios.post('/manhour/getAllMahhoursByUser', cond)
}

//员工工时明细统计
export const getAllMahhoursByUserList = function (cond = {}) {
  return Axios.post('/manhour/getAllMahhoursByUserList', cond)
}
//员工总工时数
export const getAllMahhoursCountByUser = function (cond = {}) {
  return Axios.post('/manhour/getAllMahhoursCountByUser', cond)
}

//获取当天填报的所有工时
export const getManhourByDate = function (cond) {
  return Axios.post('/manhour/getManhourByDate/' + cond)
}

//根据年月查询出当前用户当前月份填报的所有工时
export const getManhourByMonth = function (cond) {
  return Axios.post('/manhour/getManhourByMonth/' + cond)
}

//根据项目id获取审核列表
export const getManhourByProject = function (cond) {
  return Axios.post('/manhour/getManhourByProject/' + cond)
}

//录入工时/修改工时
export const saveBatchManhour = function (cond = {}) {
  return Axios.post('/manhour/saveBatchManhour', cond)
}

//提交工时
export const submitManhours = function (cond = {}) {
  return Axios.post('/manhour/submitManhours', cond)
}

//获取当前用户所有的项目工时
export const listProjectToManhour = function (cond = {}) {
  return Axios.post('project/listProjectToManhour', cond)
}

//获取审核列表
export const getAuditManhourList = function (cond = {}) {
  return Axios.post('manhour/getAuditManhourList', cond)
}

//工时填报列表
export const getManhourList = function (cond = {}) {
  return Axios.post('manhour/getManhourList', cond)
}

//部门工时汇总
export const getAllMahhoursByDept = function (cond = {}) {
  return Axios.post('/manhour/getAllMahhoursByDept', cond)
}

//部门,个人工时汇总
export const getAllMahhoursByUserAndProject = function (cond = {}) {
  return Axios.post('/manhour/getAllMahhoursByUserAndProject', cond)
}

//根据用户id获取当天总上报工时
export const getDaySum = function (cond) {
  return Axios.post('/manhour/getDaySum/' + cond)
}

//部门工时汇总点详情请求
export const getAllMahhoursByDeptAndUser = function (cond = {}) {
  return Axios.post('/manhour/getAllMahhoursByDeptAndUser', cond)
}

//工时移交
export const transferManhours = function (cond = {}) {
  return Axios.post('/manhour/transferManhours', cond)
}

//工时重新提交当天工时
export const resubmitManhour = function (cond = {}) {
  return Axios.post('/manhour/resubmitManhour', cond)
}

//个人工时列表删除工时
export const removeFailPassManhour = function (cond = {}) {
  return Axios.post('/manhour/removeFailPassManhour/' + cond)
}

//获取工时组织级类别
export const organizationManhourType = function (cond = {}) {
  return Axios.get('/manhour/organizationManhourType', cond)
}

//查看该项目工时是否填报过
export const checkXmManhour = function (cond = {}) {
  return Axios.post('/manhour/checkXmManhour', cond)
}

//员工工时按项目用户分组不分页
export const getAllMahhoursByUserAndProjectNoPage = function (cond = {}) {
  return Axios.post('/manhour/getAllMahhoursByUserAndProjectNoPage', cond)
}

//员工工时明细全部状态列表
export const mahhoursByUserList = function (cond = {}) {
  return Axios.post('/manhour/mahhoursByUserList', cond)
}

//员工补录工时明细全部状态列表
export const mahhoursByFillList = function (cond = {}) {
  return Axios.post('/manhour/mahhoursByFillList', cond)
}

//工时明细导出
export const mahhoursExpor = function (cond = {}) {
  return Axios({
    url: '/manhour/mahhoursExpor',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}

//工时补录导出
export const mahhoursFillExpor = function (cond = {}) {
  return Axios({
    url: '/manhour/mahhoursFillExpor',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}
/*绩效工时******************************************************************************************************************************/

// 绩效工时获取填报列表
export const getManhourPerformanceApplyForList = function (cond = {}) {
  return Axios.post('/manhourPerformance/getApplyForList', cond)
}

// 通过当前年月与项目id获取绩效工时数据（第一次填写绩效工时）
export const getManhourPerformanceDetailByProjectId = function (cond = {}) {
  return Axios.post('/manhourPerformance/selectDetailByProjectId', cond)
}

// 通过id获取绩效工时数据
export const getManhourPerformanceDetailById = function (cond = {}) {
  return Axios.post('/manhourPerformance/selectDetailById/' + cond)
}

// 暂存绩效工时
export const tempAddManhourPerformance = function (cond = {}) {
  return Axios.post('/manhourPerformance/add', cond)
}

// 暂存状态下编辑绩效工时
export const editManhourPerformance = function (cond = {}) {
  return Axios.post('/manhourPerformance/edit', cond)
}

// 暂存状态下提交绩效工时
export const undeterminedSubmitManhourPerformance = function (cond = {}) {
  return Axios.post('/manhourPerformance/undeterminedEditOrSubmit', cond)
}

// 查询绩效工时审核列表
export const getManhourPerformanceApprovalList = function (cond = {}) {
  return Axios.post('/manhourPerformance/listByApproval', cond)
}

// 按项目查询成员工时信息
export const selectStaffCostByProjectId = function (cond = {}) {
  return Axios.post('/manhour/selectStaffCostByProjectId', cond)
}

/*外出报告*********************************************************************************************************************/

export const selectManhourOutgoingDetailByManhourId = function (cond = {}) {
  return Axios.post('/manhourOutgoing/selectDetailByManhourId/' + cond)
}
